import useServerArea from "../../../../api/useServerArea";
import {useEffect, useMemo, useState} from "react";
import useServerMain, {useServer} from "../../../../api/useServerMain";
import {translateMeasure} from "../../../../utils/translate";
import {printPriceWithCommasAndPeriods} from "../../../../utils/functions";
import GenericTable, {DataTableInterface, SearchingInterface} from "../../../../components/misc/GenericTable";
import Check from "../../../../components/forms/GenericCheck";
import {exportExcel, formatCurrency, generatePdf} from "../../../../utils/helpers";
import {BtnActions} from "../../../../components/misc/MultipleActBtn";
import {BsFiletypeXlsx} from "react-icons/bs";
import {FaRegFilePdf} from "react-icons/fa";
import StockAviablePdf from "../../../areas/stock/reports/balance/pdfAndExcel/StockAviablePdf";
import {BasicType} from "../../../../interfaces/InterfacesLocal";
import GenericToggle from "../../../../components/misc/GenericToggle";
import Modal from "../../../../components/misc/GenericModal";
import {SubmitHandler, useForm} from "react-hook-form";
import Input from "../../../../components/forms/Input";
import Button from "../../../../components/misc/Button";
import {StockAviableInterface} from "../../../../interfaces/ServerInterfaces";
import {useServerBilling} from "../../../../api/useServerBilling";

interface Props {
    aviableStocks: StockAviableInterface[],
    dataToQuery: any
}

const FinishedProductsDisponibilityModal = ({ showReportDataModal }: { showReportDataModal: Props }) => {
    const [dataTable, setDataTable] = useState<StockAviableInterface[]>(showReportDataModal.aviableStocks)
    const { getReportFinishedProductsDisponibility, isFetching } =
        useServerBilling();
    let uniqueStockName: string[] = [];

    useEffect(() => {

    }, []);

    dataTable?.forEach((list, idx) => {
        list.stocks?.forEach((stock) => {
            if (!uniqueStockName.includes(stock.stockName)) {
                uniqueStockName.push(stock.stockName);
            }
        });
    });

    let tableTitles = ["Productos", "U/M", "Real total", "Reservado", "Disponibilidad total", "Agrupación", ...uniqueStockName];
    let tableData: DataTableInterface[];

    tableData = dataTable?.map((list, idx) => {
        const quantityMap = uniqueStockName.reduce((acc, title) => {
            const value = list?.stocks?.find((c) => c.stockName === title);
            const quantity = value ? value.quantity : 0;
            acc[title] = <span>{quantity ?? 0}</span>;
            return acc;
        }, {} as Record<string, JSX.Element>);

        const quantityByGroup: (
            quantity: number
        ) => React.ReactElement | void = (quantity) => {
            if (!!list?.enableGroup) {
                const rest = quantity % (list.groupConvertion ?? 1);
                return (
                    <div className="flex-col">
                        <div>
                            {`${Math.trunc(quantity / (list.groupConvertion ?? 1))} ${list.groupName
                            }`}
                        </div>
                        {rest !== 0 && (
                            <p>{"(+" + rest + translateMeasure(list.measure) + ")"}</p>
                        )}
                    </div>
                );
            }
        };

        return {
            rowId: list.productId,
            deletedRow: list.active === false,
            payload: {
                Productos: (
                    <div className="inline-flex gap-2 items-center">

                        <span className="font-semibold text-gay-500 ">{list.productName}</span>
                    </div>
                ),
                ...quantityMap,
                "U/M": translateMeasure(list.measure),
                "Real total": (
                    <span className="font-semibold text-gay-500 ">
                        {list.disponibility}
                    </span>
                ),
                "Reservado": (
                    <span className="font-semibold text-gay-500 ">
                        {list.reserved}
                    </span>
                ),
                "Disponibilidad total": (
                    <span className="font-semibold text-gay-500 ">
                        {list.disponibility - (list?.reserved ?? 0)}
                    </span>
                ),
                Agrupación: (
                    <span className="font-semibold text-gay-500 ">
              {quantityByGroup(list.disponibility) ?? "-"}
            </span>
                ),
            },
        };
    }) ?? [];

    const filterTable = (text: string) => {
        setDataTable(() => {
            if (text === "") return showReportDataModal.aviableStocks

            return showReportDataModal.aviableStocks.filter((item) => item.productName.toLowerCase().includes(text.toLowerCase()))
            });
    }
    const searching: SearchingInterface = {
        action: (search: string | null) =>
            filterTable(search ?? ""),
        placeholder: "Buscar Producto",
        searchDelay: false
    };
    return (
        <div>
            <GenericTable
                tableTitles={tableTitles}
                tableData={tableData}
                searching={searching}
                maxTableHeight={"500px"}
                headSticky={true}
                syncAction={{
                    action: () => getReportFinishedProductsDisponibility(showReportDataModal.dataToQuery, (resp: StockAviableInterface[]) => setDataTable(resp)),
                    loading: isFetching,
                }}
            />
        </div>
    );
};
export default FinishedProductsDisponibilityModal;