/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from "react";
import Input from "../../../../components/forms/Input";
import Select from "../../../../components/forms/Select";
import DateInput from "../../../../components/forms/DateInput";
import Button from "../../../../components/misc/Button";
import TextArea from "../../../../components/forms/TextArea";
import {BasicType, SelectInterface} from "../../../../interfaces/InterfacesLocal";
import Toggle from "../../../../components/forms/Toggle";
import { RegisterContext } from "../AllRegistersList";
import { EditContextBilling } from "../registerDetailsTabs/RegisterDetailsTab";
import { useAppSelector } from "../../../../store/hooks";
import moment from "moment";
import useServerUsers from "../../../../api/useServerUsers";
import SpinnerLoading from "../../../../components/misc/SpinnerLoading";
import useServerOnlineClients from "../../../../api/useServerOnlineClients";
import useServerEcoCycle from "../../../../api/useServerEconomicCycle";
import {filter} from "lodash";

export const RegisterDetailsStep = () => {

  const { getAllUsersForRedux, isLoading } = useServerUsers();

  const { user } = useAppSelector((store) => store.init);
  const { users } = useAppSelector((store) => store.nomenclator);

  const {
    control,
    setCurrentStep,
    currentStep,
    setValue,
    trigger,
    watch,
    clearErrors,
    isFetching,
    getValues
  } = useContext(RegisterContext);
  const { defaultValues, editMode } = useContext(EditContextBilling);

  useEffect(() => {
    getAllUsersForRedux({
      isActive: true,
      all_data: true,
    })

  }, [])
  const {getMainClientInfo, getClient, client, getAllClients} = useServerOnlineClients()
  const {setAllOrderState} = useServerEcoCycle()
  const clientId = watch!("clientId");


  useEffect(() => {
    if (clientId) {
      //getAllClients()
      !!getClient && getClient(clientId);
    }
  }, [watch!("clientId")]);

  const [filter] = useState<BasicType>({
    clientId: clientId as number,
  });

  useEffect(() => {
    //clientId &&
    //getMainClientInfo!(clientId as number, filter, setAllOrderState); /////////////////////////////////////
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, watch!("clientId")]);

  //setValue!("managedById", getValues!("clientId"))
  useEffect(() => {
    if (users !== null && users !== undefined) {
      //setValue!("managedById", user?.id);
    }
  }, [watch!("managedById")]);

  // const verifiedRoles = ["ADMIN", "MANAGER_SALES", "OWNER", "MANAGER_BILLING", "MARKETING_SALE"]
  const selectManagedData: SelectInterface[] = [];

  if (users !== null && users !== undefined) {
    //Select asociated area data ---------------------------------------------------------------------
    users?.map((item) => {
    // users?.filter(user => user?.roles?.find(rol => verifiedRoles.includes(rol.code)))?.map((item) => {
      selectManagedData.push({
        name: `${item.username ?? ""}/ ${item.displayName ?? ""}`,
        id: item.id,
      });
    });

    if(!users.find(userItem => userItem.id === user?.id)){
      selectManagedData.push({
        name: `${user?.username ?? ""}/ ${user?.displayName ?? ""}`,
        id: user?.id!,
      });
    }
  }
  let commercialDefaultValue = editMode ? defaultValues?.managedBy?.id : user?.id
  if (client?.commercial) {
    commercialDefaultValue = client?.commercial?.id
  }

  const paymentsDeadlineSelect: SelectInterface[] = [
    {
      id: 7,
      name: "07 días",
    },
    {
      id: 15,
      name: "15 días",
    },
    {
      id: 30,
      name: "30 días",
    },
    {
      id: 45,
      name: "45 días",
    },
    {
      id: 60,
      name: "60 días",
    },
    {
      id: "manual",
      name: "Manual",
    },
  ];

  const timeLimit = watch!("timeLimit");
  const paymentDeadline = watch!("paymentDeadlineAt");
  const name = watch!("name");
  const managedById = watch!("managedById");
  const currencyBilling = watch!("currencyBilling");
  const havePaymentDeadline = watch!("havePaymentDeadline");

  const handlerDueDate = () => {
    if (timeLimit !== "manual" && timeLimit !== undefined) {
      let date = new Date();
      let newDate = new Date(date.getTime());
      newDate.setDate(newDate.getDate() + timeLimit);
      setValue!("paymentDeadlineAt", newDate);
    }
  };

  const onSubmit = async () => {
    if (
      await trigger!([
        "paymentDeadlineAt",
        "name",
        "managedById",
        "salesBy",
        "currencyBilling",
        "registeredAt",
      ])
    ) {
      // setValue!('registeredAt', registeredAt)
      setValue!("timeLimit", timeLimit);
      setValue!("name", name);
      setValue!("managedById", managedById);
      setValue!("currencyBilling", currencyBilling);
      setValue!("paymentDeadlineAt", paymentDeadline);
      setValue!("havePaymentDeadline", havePaymentDeadline);

      setCurrentStep!(currentStep! + 1);
    }
  };

  useEffect(() => {
    handlerDueDate();
  }, [timeLimit]);

  const registeredAt = moment(defaultValues?.registeredAt ?? "").startOf("day");
  const paymentDeadlineAt = moment(defaultValues?.paymentDeadlineAt ?? "").startOf("day");

  const differenceInDays = paymentDeadlineAt?.diff(registeredAt, "days")

  if (isLoading) {
    return <div className="p-8 min-h-[25rem] flex items-center justify-center w-full">
      <SpinnerLoading />
    </div>
  }

  return (
    <div>
      <div className="grid gap-4 px-8 items-center min-h-[25rem]">
        {/* first Row */}
        <div className="grid  grid-cols-2 gap-2 items-center">
          {/* Titulo */}
          <div className="col-span-2">
            <Input
              label="Título"
              name="name"
              control={control}
              defaultValue={editMode? defaultValues?.name : null}
            />
          </div>

          {/* Comercial */}
          <div className="col-span-2">
            <Select
              data={selectManagedData}
              label="Comercial que atiende"
              name="managedById"
              control={control}
              rules={{
                required: "Seleccione un usuario de comercial",
              }}
              defaultValue={commercialDefaultValue}
              disabled={!!client?.commercial?.id}
            />
          </div>
        </div>
        {/* Secound Row */}
        <div className="grid grid-cols-2 gap-2 items-center">
          {/* Fecha de emision */}
          <div className="col-span-2">
            <Toggle
              title="Establecer plazo de pago"
              control={control}
              name="havePaymentDeadline"
              defaultValue={
                typeof defaultValues?.paymentDeadlineAt === "string" ||
                watch!("havePaymentDeadline")
              }
            />
          </div>
          {havePaymentDeadline && (
            <>
              <div>
                <Select
                  label="Plazo de pago"
                  data={paymentsDeadlineSelect}
                  name="timeLimit"
                  control={control}
                  placeholder={
                    defaultValues !== undefined
                      ? `${differenceInDays} días`
                      : "Seleccione"
                  }
                  rules={{
                    onChange: (e) => {
                      if (e.target.value !== "manual") {
                        clearErrors!("paymentDeadlineAt");
                      }
                    },
                  }}
                />
              </div>
              <div className="pt-1">
                <DateInput
                  label="Fecha de vencimiento (*)"
                  name="paymentDeadlineAt"
                  control={control}
                  disabled={timeLimit !== "manual"}
                  fromToday
                  defaultValue={
                    editMode ? defaultValues?.paymentDeadlineAt : ""
                  }
                  rules={{
                    required: "Este campo es requerido",
                    onChange: (e) => clearErrors!("paymentDeadlineAt"),
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="grid">
          <TextArea
            label="Observaciones"
            name="observations"
            control={control}
            defaultValue={editMode && defaultValues?.observations}
          />
        </div>
        <div className="flex w-full row-span-2 items-end">
          {!editMode ? (
            <div className="grid grid-cols-2 w-full gap-3 pt-2 ">
              <Button
                name="Atras"
                color="white"
                textColor="blue-800"
                outline
                type="button"
                action={() => {
                  setCurrentStep!(currentStep! - 1);
                }}
                full
                disabled={currentStep === 0 ? true : false}
              />
              <Button
                name="Continuar"
                color="slate-700"
                type="button"
                action={onSubmit}
                full
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {editMode && (
          <div className="flex w-full row-span-2 items-end justify-end">
            <div className="grid">
              <Button
                name={editMode ? "Actualizar" : `Registrar`}
                color="slate-700"
                type="submit"
                full
                loading={isFetching}
                disabled={isFetching}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
