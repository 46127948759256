import { useEffect, useState } from "react";
import Button from "../../../../components/misc/Button";
import Modal from "../../../../components/misc/GenericModal";
import { useAppSelector } from "../../../../store/hooks";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import {
    formatCurrencyWithOutCurrency,
    formatDateHours,
    generatePdf,
    mathOperation,
} from "../../../../utils/helpers";
import PdfFinancialEconomicCycleReporte from "../pdfs/PdfFinancialEconomicCycleReporte";
import { getColorCashOperation } from "../../../../utils/tailwindcss";
import moment from "moment";
import { getCashOperationSpanish } from "../../../../utils/functions";
import CalendarEconomicCycle from "./CalendarEconomicCycle";
import Toggle from "../../../../components/forms/Toggle";
import RadioGroupForm from "../../../../components/forms/RadioGroup";
import MultiSelect from "../../../../components/forms/Multiselect";
import useServerEcoCycle from "../../../../api/useServerEconomicCycle";
import CashRegisterReport from "../report/CashRegisterReport";
import DateInput from "../../../../components/forms/DateInput";
import { useServerBilling } from "../../../../api/useServerBilling";
import { FaRegFilePdf } from "react-icons/fa";
import PdfSalesSummaryByCommercial from "../pdfs/PdfSalesSummaryByCommercial";
import SalesSummaryByCommercial from "../report/SalesSummaryByCommercial";
import {OrdersManagedByInterface, StockAviableInterface} from "../../../../interfaces/ServerInterfaces";
import FinishedProductsDisponibility from "../report/FinishedProductsDisponibility";
import useServerArea from "../../../../api/useServerArea";

interface FormDataInterface {
    listStockIds?: number[];
    preBillingMatch?: boolean;
    deliveryAt?: string
}

function SalesSummaryByCommercialModal({ setShowModal }: any) {
    const { getAllAreas, allAreas } = useServerArea();
    const { getReportFinishedProductsDisponibility, isFetching } =
        useServerBilling();
    const [showReportDataModal, setShowReportDataModal] =
        useState<{
            aviableStocks: StockAviableInterface[],
            dataToQuery: FormDataInterface
    } | null>();

    useEffect(() => {
        getAllAreas({ type: "STOCK", all_data: true, inAllMyBusiness: true });
    }, []);

    //React Hook Form
    const { handleSubmit, control, watch } = useForm();

    const onSubmit: SubmitHandler<FormDataInterface> = async (data) => {
        getReportFinishedProductsDisponibility(data, (resp: StockAviableInterface[]) => {
            setShowReportDataModal({
                aviableStocks: resp,
                dataToQuery: data
            })
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Disponibilidad de productos terminados
                </h2>
                <div className="flex flex-col gap-2 w-full">
                    <div className="py-1 col-span-2">
                        <MultiSelect
                            name="listStockIds"
                            data={allAreas}
                            label="Áreas *"
                            control={control}
                            rules={{required: "Este campo es requerido"}}
                        />
                    </div>
                    <Toggle
                        name="preBillingMatch"
                        title="Hacer match con pedidos"
                        defaultValue={false}
                        control={control}
                    ></Toggle>
                    {watch!("preBillingMatch") &&
                        <div className="ms-5 -mt-1">
                            <DateInput
                                name="deliveryAt"
                                label="Fecha de entrega *"
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                }}
                                includeTime={false}
                            />
                        </div>
                    }


                    <div className="w-full flex justify-end gap-3 mt-4">
                        <div>
                            <Button
                                color="slate-600"
                                textColor="slate-600"
                                type="submit"
                                name="Cancelar"
                                outline
                                action={() => {
                                    setShowModal(false);
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                color="slate-600"
                                type="submit"
                                name="Generar"
                                loading={isFetching}
                                disabled={isFetching}
                            />
                        </div>
                    </div>
                </div>
            </form>

            {!!showReportDataModal && (
                <Modal
                    state={!!showReportDataModal}
                    close={() => setShowReportDataModal(null)}
                    size="l"
                >
                    <FinishedProductsDisponibility showReportDataModal={showReportDataModal} />
                </Modal>
            )}
        </>
    );
}

export default SalesSummaryByCommercialModal;
