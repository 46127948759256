/* eslint-disable array-callback-return */
import React from "react";
import GenericTable, { DataTableInterface } from "../../../../components/misc/GenericTable";
import { printPriceWithCommasAndPeriods } from "../../../../utils/functions";
import { salesOrdersWithMessaging } from "../../../../interfaces/ServerInterfaces";
import { formatCalendar } from "../../../../utils/helpers";

export default function SalesByOrdersWithMessagingReport(props: any) {


    const { salesbyOrdersWithMessaging, isLoading } = props.dataAccess;

    const tableTitles = [
        "Nombre",
        "Municipio",
        "Localidad",
        "Número de operación",
        "Correo electrónico",
        "Fecha de entrega",
        "Envío",
    ];

    const tableData: DataTableInterface[] = [];

    salesbyOrdersWithMessaging?.forEach((elem: any, idx: number) => {

        const totalsMap: { [currency: string]: number } = {};

        elem.shipping.forEach((shipping: { price: { codeCurrency: any; amount: any; }; }) => {
            const { codeCurrency, amount } = shipping.price;

            if (!!codeCurrency && !!amount) {
                if (!totalsMap[codeCurrency]) {
                    totalsMap[codeCurrency] = 0;
                }

                totalsMap[codeCurrency] += amount;
            }
        });

        const totals: { currency: string, amount: number }[] = Object.keys(totalsMap).map(currency => ({
            currency,
            amount: totalsMap[currency]
        }));

        const shippingRegions = elem.shipping.map((shippingRegion: any) => {
            const { codeCurrency, amount } = shippingRegion.price;
            if (!!codeCurrency && !!amount) {
                return shippingRegion
            } else {
                return null
            }

        })

        tableData.push({
            payload: {
                'Nombre': <p className="font-semibold">{elem?.name}</p>,
                "Municipio": "",
                "Localidad": "",
                "Número de operación": "",
                'Correo electrónico': <p className="font-semibold">{elem?.email}</p>,
                "Fecha de entrega": "",
                'Envío': totals.map(elem => {
                    return (
                        printPriceWithCommasAndPeriods(elem?.amount) + " " + elem?.currency
                    )
                }).join(", "),
            },
            rowId: idx+1,
            childRows: shippingRegions.filter((region: any) => region !== null).map((shippingRegion: any) => {
                const { codeCurrency, amount } = shippingRegion.price!;
                return {
                    payload: {
                        'Nombre': "",
                        "Municipio": <p className="font-semibold">{(shippingRegion?.region?.municipality)}</p>,
                        "Localidad": <p className="font-semibold">{(shippingRegion?.region?.city)}</p>,
                        "Número de operación": <p className="font-semibold">{(shippingRegion?.operationNumber)}</p>,
                        'Correo electrónico': "",
                        "Fecha de entrega": <p className="font-semibold">{formatCalendar(shippingRegion?.deliveryAt)}</p>,
                        'Envío': printPriceWithCommasAndPeriods(amount) + " " + codeCurrency,
                    },

                }

            })

        })

    })

    function calculateTotalsByCurrency(elem: salesOrdersWithMessaging[]): Total[] {
        const totalsMap: { [currency: string]: number } = {};

        elem.forEach(item => {
            item.shipping.forEach(shipping => {
                const { codeCurrency, amount } = shipping.price;

                if (codeCurrency && amount != null) {
                    if (!totalsMap[codeCurrency]) {
                        totalsMap[codeCurrency] = 0;
                    }

                    totalsMap[codeCurrency] += amount;
                }
            });
        });

        const totals: Total[] = Object.keys(totalsMap).map(currency => ({
            currency,
            amount: totalsMap[currency]
        }));

        return totals;
    }

    const totalsByCurrency: Total[] = calculateTotalsByCurrency(salesbyOrdersWithMessaging);

    tableData.push({
        payload: {
            'Nombre': <p className="font-semibold">Totales</p>,
            "Municipio": "",
            "Localidad": "",
            "Número de operación": "",
            'Correo electrónico': "",
            "Fecha de entrega": "",
            'Envío': <p className="whitespace-nowrap">{totalsByCurrency.map(elem => {
                return (
                    printPriceWithCommasAndPeriods(elem?.amount) + " " + elem?.currency
                )
            }).join(", ")}</p>,
        }
    })
    //-----------------------------------------------------------------------------

    console.log('tableData!!!!!!!!',tableData);

    return (
        <>
            <div className={props.show ? '' : 'hidden'}>
                <GenericTable
                    tableTitles={tableTitles}
                    tableData={tableData}
                    loading={isLoading}
                />

                {/*  { <ReactTooltip place="top" id="my-tooltip" />} */}
            </div>

        </>
    );
}


interface Total {
    currency: string;
    amount: number;
}














