import { useState } from 'react'
import EmptyList from './EmptyList'
import SearchComponent from './SearchComponent'
import LoadingSpin from './LoadingSpin';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";

interface SelectInTwoTablesInterface {
    data: {
        id: number,
        name: string,
        addicionalInfo?: string
    }[];
    selectedItems: any[];
    setSelectedItems: Function;
    leftTableText?: string;
    rigthTableText?: string;
    loading?: boolean;
    heigthFull?: boolean;
}

const SelectInTwoTables = ({
    data,
    selectedItems,
    setSelectedItems,
    leftTableText,
    rigthTableText,
    loading,
    heigthFull
}: SelectInTwoTablesInterface) => {


    const [search, setSearch] = useState<string | null>(null);

    const handleLeftTableButtonClick = (item: { id: any; name?: string; addicionalInfo?: string | undefined; }) => {
        if (selectedItems.filter(elem => elem.id === item.id).length === 0) {
            setSelectedItems([...selectedItems, item])
        }
    }

    return (
        <>
            <div className={`grid grid-cols-2 ${heigthFull === true ? "h-full" : "h-96"}  p-4 rounded-md justify-center gap-2`}>
                {!!leftTableText && <h2 className='w-full text-center mt-2 font-normal text-l'>{leftTableText}</h2>}
                {/* Left Table */}
                <div className="border border-slate-300 p-2 rounded overflow-y-auto scrollbar-thin">
                    <div className="sticky -top-3 bg-gray-50 p-2 rounded inline-flex w-full gap-x-2">
                        <SearchComponent
                            findAction={(find: string | null) => setSearch!(find)}
                            placeholder="Buscar elemento"
                        />
                    </div>
                    {/* <ProductSelectableList setQuantity={setProductQuantityModal} /> */}
                    {
                        loading === true
                            ? <div className='w-full flex items-center justify-center mt-24'><LoadingSpin color='black' /> </div>
                            :
                            data.filter(elem => !selectedItems.find(elem2 => elem2.id === elem.id)).length === 0
                                ? <div className='scrollbar-none h-96 w-full flex items-center justify-center'><EmptyList size='s' /></div>
                                : <div>
                                    {
                                        (search === null || search === "")
                                            ?
                                            data.filter(elem => !selectedItems.find(elem2 => elem2.id === elem.id)).map((item, idx) => (
                                                <button
                                                    type='button'
                                                    key={idx}
                                                    className={`inline-flex w-full shadow-md items-center border border-slate-200 p-5 rounded-md my-2  hover:bg-slate-50 
                                                    }`}
                                                    // ${selectedItems.filter(elem => elem.id === item.id).length === 0 ? "bg-white hover:bg-slate-50" : "bg-green-100 hover:bg-g-50"}
                                                    onClick={() => handleLeftTableButtonClick(item)}
                                                >
                                                    <div className="flex flex-col flex-grow gap-1">
                                                        <span className="self-start">
                                                            {/* <ProductTypeBadge type={item.type ?? ""} /> */}
                                                        </span>
                                                        <p className="text-md font-semibold text-start">{item.name}</p>
                                                        <div className="inline-flex items-center gap-5">
                                                            <p className="block text-sm text-slate-400 text-start">
                                                                {(item?.addicionalInfo! ?? "")}
                                                            </p>

                                                        </div>


                                                    </div>
                                                    <ArrowRightIcon className="h-5 w-5 text-customBlue-500" aria-hidden="true" />

                                                </button>
                                            ))
                                            : data.filter(item => item.name.includes(search) && !selectedItems.find(elem2 => elem2.id === item.id)).length > 0
                                                ? data.filter(item => item.name.includes(search) && !selectedItems.find(elem2 => elem2.id === item.id)).map((item, idx) => (
                                                    <button
                                                        type='button'
                                                        key={idx}
                                                        className={`inline-flex w-full shadow-md items-center border border-slate-200 p-5 rounded-md my-2  "hover:bg-slate-50"
                                                }`}
                                                        onClick={() => handleLeftTableButtonClick(item)}
                                                    >
                                                        <div className="flex flex-col flex-grow gap-1">
                                                            <span className="self-start">
                                                                {/* <ProductTypeBadge type={item.type ?? ""} /> */}
                                                            </span>
                                                            <p className="text-md font-semibold text-start">{item.name}</p>
                                                            <div className="inline-flex items-center gap-5">
                                                                <p className="block text-sm text-slate-400 text-start">
                                                                    {(item?.addicionalInfo! ?? "")}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <ArrowRightIcon className="h-5 w-5 text-customBlue-500" aria-hidden="true" />
                                                    </button>
                                                ))
                                                : <div className='scrollbar-none h-96 w-full flex items-center justify-center'><EmptyList size='s' title='No hay resultados en la búsqueda' /></div>
                                    }
                                </div>
                    }

                </div>

                {/* Rigth Table */}
                <div className="border border-slate-300 p-2 rounded overflow-y-auto scrollbar-thin">
                    {!!rigthTableText && <h2 className='w-full text-center mt-2 font-normal text-l'>{rigthTableText}</h2>}
                    <div className="sticky top-0 p-1 rounded text-sm">

                        {
                            loading === true
                                ? <div className='w-full flex items-center justify-center mt-24'><LoadingSpin color='black' /> </div>
                                : (selectedItems.length === 0 || selectedItems === null)
                                    ? <div className='scrollbar-none h-96 w-full flex items-center justify-center'><EmptyList size='s' /></div>
                                    : selectedItems.map((item, idx) => (
                                        <button
                                            type='button'
                                            key={idx}
                                            className={`inline-flex w-full shadow-md items-center border border-slate-200 p-5 rounded-md my-2  "hover:bg-slate-50"}`}
                                            onClick={() => setSelectedItems(selectedItems.filter(elem => elem.id !== item.id))}
                                        >
                                            <ArrowLeftIcon className="h-5 w-5 text-customBlue-500" aria-hidden="true" />

                                            <div className="ml-4 flex flex-col flex-grow gap-1">
                                                <span className="self-start">
                                                    {/* <ProductTypeBadge type={item.type ?? ""} /> */}
                                                </span>
                                                <p className="text-md font-semibold text-start">{item.name}</p>
                                                <div className="inline-flex items-center gap-5">
                                                    <p className="block text-sm text-slate-400 text-start">
                                                        {(item?.addicionalInfo! ?? "")}
                                                    </p>
                                                </div>
                                            </div>

                                        </button>
                                    ))
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectInTwoTables
