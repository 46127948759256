import useServerEcoCycle from "../../../../api/useServerEconomicCycle";
import {useState} from "react";
import {FieldValues, SubmitHandler} from "react-hook-form";
import SearchCriteriaComponent, {
    BasicTypeFilter,
    DateTypeFilter,
    SelectTypeFilter
} from "../../../../components/misc/SearchCriteriaComponent";
import Modal from "../../../../components/misc/GenericModal";
import {BasicType, SelectInterface} from "../../../../interfaces/InterfacesLocal";
import {useAppSelector} from "../../../../store/hooks";
import {deleteUndefinedAttr} from "../../../../utils/helpers";
import {IoAlertCircleOutline} from "react-icons/io5";
import SalesByGrossMerchandiseReport from "../../../economicCycles/analysis/report/SalesByGrossMerchandiseReport";

export function SalesByProcessingAreaModal() {


    const {getSelledReport, selledReport, isLoading} = useServerEcoCycle();

    const [showReportDataModal, setShowReportDataModal] = useState<
        boolean | null
    >();

    const {areas} = useAppSelector(
        (state) => state.nomenclator
    );
    const [clearFilters, setClearFilters] = useState(false);

    const processAreaSelector: SelectInterface[] = areas
        .filter((item) => item.type === "MANUFACTURER")
        .map((area) => ({
            id: area.id,
            name: area.name,
        }));

    //Submit form ----------------------------------------------------------------------------------
    const onSubmit: SubmitHandler<BasicType> = (data) => {
        setShowReportDataModal(true);
        if (Object.keys(data).length > 0) {
            const allFilters = deleteUndefinedAttr({
                ...data,
            });
            getSelledReport(allFilters);
            setClearFilters(false);
        } else setClearFilters(true);
    };

    //Management filters ------------------------------------------------------------------------
    const availableFilters: (
        | BasicTypeFilter
        | DateTypeFilter
        | SelectTypeFilter
        )[] = [
        {
            name: "dateRange",
            isRequired: true,
            label: "Rango de fechas",
            type: "datepicker-range-including-time",
            datepickerRange: [
                {
                    name: "dateFrom",
                    label: "Desde",
                    isUnitlToday: true,
                },
                {
                    name: "dateTo",
                    label: "Hasta",
                    isUnitlToday: true,
                },
            ],
        },

        {
            label: "Áreas de procesado",
            name: "productionAreaId",
            type: "select",
            isRequired: true,
            data: processAreaSelector
        },
    ];

    //-----------------------------------------------------------------------------

    return (
        <>
            <h2 className="text-xl font-semibold mb-6">Resumen de ventas en las áreas de procesado</h2>
            <div
                className="flex items-center space-x-2 p-2 border-t-4 border-yellow-500 mb-2 bg-gradient-to-b from-yellow-100 to-yellow-50 rounded-md">
                <div className="flex items-center justify-center h-6 w-6 rounded-full bg-transparent">
                    <IoAlertCircleOutline
                        size={24}
                        style={{backgroundColor: "transparent"}}
                        className="text-yellow-500 bg-transparent"
                    />
                </div>
                <p className="text-sm text-gray-700">
                    Este reporte solo tiene en cuenta el valor de la mercancía/producto
                    vendido individualmente. No incluye los descuentos, comisiones,
                    cupones aplicados a la orden/factura.
                </p>
            </div>

            <SearchCriteriaComponent
                filterAction={(data: FieldValues) => onSubmit(data)}
                filters={availableFilters}
                disableCriteriaSearch={true}
                loading={isLoading}
            />

            {!!showReportDataModal && (
                <Modal
                    state={showReportDataModal}
                    close={() => setShowReportDataModal(null)}
                    size="l"
                >
                    <h2 className="text-xl font-semibold mb-6">
                        Resumen de ventas en las áreas de procesado
                    </h2>
                    <SalesByGrossMerchandiseReport
                        selledReport={selledReport}
                        isLoading={isLoading}
                        clearFilters={clearFilters}
                    />
                </Modal>
            )}
        </>
    );
}