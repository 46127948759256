import { RadioGroup } from "@headlessui/react";
import { useController, UseControllerProps } from "react-hook-form";

interface BasicCustomRadioDataInterface {
    id: string | number;
}

interface CustomRadioInterface<T extends BasicCustomRadioDataInterface> {
    data: T[];
    action?: (value: string | number) => void;
    className?: string;
    itemComponent: (item: T, checked: boolean) => React.ReactNode;
    endElement?: (item: T) => React.ReactNode;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export default function GenericCustomRadio<T extends BasicCustomRadioDataInterface>(
    props: UseControllerProps & CustomRadioInterface<T>
) {
    const { data, action, className, itemComponent, endElement } = props;
    const { field } = useController(props);

    const onChange = (value: string | number) => {
        field.onChange(value);
        if (action) action(value); // Call the action only if it exists
    };

    return (
        <div className="p-2">
            <RadioGroup value={field.value} onChange={onChange}>
                <div className="space-y-4 flex flex-col">
                    {data?.map((item) => (
                        <div key={item.id} className="flex gap-x-2">
                            <RadioGroup.Option
                                value={item.id}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked ? "border-2 border-slate-600" : "border-gray-300",
                                        active ? "border-slate-600 ring-2 ring-slate-600" : "",
                                        "cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none flex flex-col text-sm",
                                        className ?? ""
                                    )
                                }
                            >
                                {({ checked }) => (
                                    <>
                                        {itemComponent(item, checked)}
                                    </>
                                )}
                            </RadioGroup.Option>

                            {endElement && (
                                <div className="flex justify-center items-center flex-shrink">
                                    {endElement(item)}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
}