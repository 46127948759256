import {useState} from "react";
import GenericTable, {DataTableInterface} from "../../../components/misc/GenericTable";
import Breadcrumb, {PathInterface} from "../../../components/navigation/Breadcrumb";
import Modal from "../../../components/misc/GenericModal";
import {AiOutlineFire} from "react-icons/ai";
import {SalesByProcessingAreaModal} from "./modals/SalesByProcessingAreaModal";


const ProductionOrdersAnalysis = ({breadcrumb = true}) => {
    //ShowModalsState--------------------------------------------------------------------------------------

    const [showModalSalesByProcessingArea, setShowModalSalesByProcessingArea] = useState(false);

//-----------------------------------------------------------------------------------------------------

//Data to dislay in table ---------------------------------------------------------------------------
    const tableTitle: string[] = ["Nombre del reporte"];

    const reportsType = [
        {
            id: 1,
            name: "Resumen de ventas en las áreas de procesado",
        },
    ];

    const tableData: DataTableInterface[] = [];
    reportsType.map(({id, name}) => {
        tableData.push({
            rowId: id,
            payload: {
                "Nombre del reporte": name,
            },
        });
    });

// Row table actions
    const rowAction = (id: number) => {
        if (id === 1) {
            setShowModalSalesByProcessingArea(true);
        }
    };

//Breadcrumb-----------------------------------------------------------------------------------
    const paths: PathInterface[] = [
        {
            name: "Producción",
        },
        {
            name: "Análisis",
        },
    ];

    return (
        <>
            {breadcrumb && (
                <Breadcrumb
                    icon={<AiOutlineFire className="h-6 text-gray-500"/>}
                    paths={paths}
                />
            )}

            <GenericTable
                tableData={tableData}
                tableTitles={tableTitle}
                rowAction={rowAction}
            />

            {showModalSalesByProcessingArea && (
                <Modal
                    state={showModalSalesByProcessingArea}
                    close={() => setShowModalSalesByProcessingArea(false)}
                >
                    {<SalesByProcessingAreaModal />}
                </Modal>
            )}
        </>
    );
}
export default ProductionOrdersAnalysis