import Button from "../../../components/misc/Button";
import {useContext, useEffect, useState} from "react";
import {OnlineClientInterface} from "../../../interfaces/ServerInterfaces";
import {EditClientCtx} from "./EditOnlineClientContainer";
import {SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {BasicType, SelectInterface} from "../../../interfaces/InterfacesLocal";
import {useAppSelector} from "../../../store/hooks";
import ComboBox from "../../../components/forms/Combobox";
import {TrashIcon} from "@heroicons/react/24/outline";
import AlertContainer from "../../../components/misc/AlertContainer";
import Modal from "../../../components/misc/GenericModal";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

interface EditSettingsClientComponent {
  client: OnlineClientInterface;
  deleteClient: Function;
  isFetching: boolean;
  callback?: (client: OnlineClientInterface) => void;
  // editClient: Function;
}

export const EditSettingsClient = ({
                                     deleteClient,
                                     client,
                                     callback,
                                     isFetching,
                                   }: EditSettingsClientComponent) => {
  const {editClient} = useContext(EditClientCtx);
  const [deleteModal, setDeleteModal] = useState(false);
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const mainPath = pathname.split("/")[1];

  const {users} = useAppSelector((store) => store.nomenclator);

  const {control, handleSubmit, setValue} = useForm({mode: "onChange"})
  const selectManagedData: SelectInterface[] = [{
    id: null,
    name: "Sin asignar"
  }];
  const onSubmit: SubmitHandler<BasicType> = (data) => {
    editClient && editClient(client?.id, data)
  }

  if (users !== null && users !== undefined) {
    //Select asociated area data ---------------------------------------------------------------------
    users?.map((item) => {
      // users?.filter(user => user?.roles?.find(rol => verifiedRoles.includes(rol.code)))?.map((item) => {
      selectManagedData.push({
        name: `${item.username ?? ""}/ ${item.displayName ?? ""}`,
        id: item.id,
      });
    });
  }

  return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex w-full gap-6 h-[27.1rem]">
            <div className="w-full overflow-hidden p-5">
              <ComboBox
                  data={selectManagedData}
                  label="Comercial asignado"
                  name="commercialId"
                  control={control}
                  defaultValue={client?.commercial?.id}
              />

            </div>

            <div className="flex w-[10%] h-fit justify-end">
              <Button
                  color="red-500"
                  icon={<TrashIcon className="h-5 text-red-500"/>}
                  action={() => setDeleteModal(true)}
                  outline
              />
            </div>
          </div>
          <div className="flex justify-end mt-3">
            <Button name="Actualizar" color="slate-600" type="submit"/>
          </div>
        </form>
        {deleteModal && (
            <Modal state={deleteModal} close={setDeleteModal}>
              <AlertContainer
                  onAction={() =>
                      deleteClient(
                          client.id,
                          mainPath === "clients"
                              ? () => {
                                navigate("/clients/all")
                              }
                              : callback
                      )
                  }
                  onCancel={setDeleteModal}
                  text="Seguro que desea eliminar este cliente?"
                  title={`Eliminar cliente: ${client.firstName}`}
                  loading={isFetching}
              />
            </Modal>
        )}
      </div>

  );
};
