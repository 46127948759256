import { SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import DateInput from "../../../components/forms/DateInput";
import Button from "../../../components/misc/Button";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import { exportExcel } from "../../../utils/helpers";
import useServerBankAccount from "../../../api/useServerBankAccount";

const BankAccountOperations = () => {
  const { control, handleSubmit } = useForm<Record<string, string | number>>();
  const { getAllBankAccountOperations } = useServerBankAccount();

  const exportAccountBalance = async (data: any) => {
    try {
      const response = await getAllBankAccountOperations(data?.accountId, { dateFrom: data?.dateFrom, dateTo: data?.dateTo }, true);
      
      let dataToExport;
      const mapItem = (item: any) => ({
        Concepto: item?.accountTag ? item?.accountTag?.name : "---",
        Fecha:
          item?.operation === "balance"
            ? moment(item.registeredAt).format("DD-MM-YYYY")
            : moment(item.registeredAt).format("DD-MM-YYYY hh:mm A"),
        Monto: item?.amount?.amount ?? "-",
        Subcuenta: item?.subAccount ? item?.subAccount?.name : "---",
        Moneda: item?.amount?.codeCurrency,
        "Hecho por": item?.madeBy?.displayName ?? "",
        Descripción: item.description,
      });

      dataToExport = response.map(mapItem);
  
      exportExcel(dataToExport, 'balance');
    } catch (error) {
      console.error('Error al exportar el balance:', error);
    }
  };
    
  const onSubmit: SubmitHandler<Record<string, string | number>> = (data) => {
    exportAccountBalance(data);
  };

  return (
    <>
      <h2 className="text-xl font-semibold mb-6">Listado de operaciones</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-2"> 
          <DateInput
                includeTime={true}
                label={"Desde"}
                control={control}
                untilToday={true}
                defaultValue={null}
                name={"dateFrom"}
                rules={{ required: "Este campo es requerido" }}
            />
            <DateInput
                includeTime={true}
                label={"Hasta"}
                control={control}
                untilToday={true}
                defaultValue={null}
                name={"dateTo"}
                rules={{ required: "Este campo es requersido" }}
            />         
        </div>
        <div className="grid grid-cols-1 gap-2 mt-3">
          <AsyncComboBox
            dataQuery={{
              url: "/administration/bank/account",
              defaultParams: { page: 1 },
            }}
            normalizeData={{ id: "id", name: ["name"] }}
            name="accountId"
            control={control}
            label="Cuentas *"
            placeholder="Seleccione un cuenta"
            rules={{required:true}}
          />
        </div>

        <div className="mt-3 w-full flex justify-end items-center gap-2">          
          <Button
              color={"slate-600"}
              type="submit"
              name="Aceptar"
              textColor={"white"}
          />
      </div>
      </form>
    </>
  );
}

export default BankAccountOperations;
