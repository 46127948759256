import React, { useState } from "react";
import {
  ExtendedNomenclator,
  PriceInvoiceInterface,
} from "../../../../interfaces/ServerInterfaces";
import GenericTable, {
  DataTableInterface,
} from "../../../../components/misc/GenericTable";
import Modal from "../../../../components/misc/GenericModal";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../../components/forms/Input";
import Button from "../../../../components/misc/Button";
import { SelledProductReport } from "../../../../interfaces/InterfacesLocal";
import {
  exportExcel,
  formatCurrency,
  roundTwoDecimals,
} from "../../../../utils/helpers";
import { BtnActions } from "../../../../components/misc/MultipleActBtn";
import { BsFiletypeXlsx } from "react-icons/bs";
import GenericToggle from "../../../../components/misc/GenericToggle";
import { translateMeasure } from "../../../../utils/translate";
import useServer from "../../../../api/useServerMain";
import {sum} from "lodash";

export default function SalesByGrossMerchandiseReport({
  selledReport,
  isLoading,
  clearFilters,
}: any) {
  const [exportModal, setExportModal] = useState(false);
  const { allowRoles } = useServer()
  const [isGroupedByCategory, setIsGroupedByCategory] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("")
  const [orderBy, setOrderBy] = useState(0);
  const orderByString = (a: any, b: any) => {
    let x = a.salesCategory.toLowerCase();
    let y = b.salesCategory.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  //Data for table ----------------------------------------------------------------------------
  const dataByCategories: SelledProductReport[] = [];
  selledReport &&
    !clearFilters &&
    selledReport?.products?.forEach((product: any) => {
      if (searchText !== "" && !(product.name as string).toLowerCase().includes(searchText.toLowerCase())) return
      const prod = {
        name: product.name,
        areaSale: product.areaSales,
        salesPrice: product.totalSales,
        quantity: product.quantitySales,
        totalCost: product.totalCost,
        totalSale: product.totalSales,
        enableGroup: product.enableGroup,
        groupConvertion: product.groupConvertion,
        groupName: product.groupName,
        totalQuantity: product.totalQuantity,
        totalFixedCost: product.totalFixedCost
      };
      const found = dataByCategories.find(
        (item) =>
          item.salesCategory === product.salesCategory || !isGroupedByCategory
      );
      if (found) {
        if (orderBy === 1) {
          let i = 0;
          while (
            i < found.products.length &&
            prod.quantity < found.products[i].quantity
          ) {
            i++;
          }
          found.products.splice(i, 0, prod);
        } else if (orderBy === 2) {
          let i = 0;
          while (
            i < found.products.length &&
            prod.quantity > found.products[i].quantity
          ) {
            i++;
          }
          found.products.splice(i, 0, prod);
        } else {
          found.products.push(prod);
        }
        found.subtotals = {
          quantity: prod.quantity + (found.subtotals?.quantity ?? 0),
          totalCost: {
            amount:
              prod.totalCost.amount + (found.subtotals?.totalCost?.amount ?? 0),
            codeCurrency: prod.totalCost.codeCurrency,
          },
          totalFixedCost: {
            amount:
                prod.totalFixedCost.amount + (found.subtotals?.totalFixedCost?.amount ?? 0),
            codeCurrency: prod.totalCost.codeCurrency,
          },
          totalSale: (function () {
            const totals: PriceInvoiceInterface[] = [
              ...found.subtotals.totalSale!,
            ];
            prod.totalSale.forEach((itm: any) => {
              const idx = totals.findIndex(
                (elem) => elem.codeCurrency === itm.codeCurrency
              );
              if (idx !== -1) {
                totals.splice(idx, 1, {
                  ...totals[idx],
                  amount: itm.amount + totals[idx].amount,
                });
              } else {
                totals.push(itm);
              }
            });
            return totals;
          })(),
        };
      } else {
        dataByCategories.push({
          salesCategory: product.salesCategory,
          products: [prod],
          subtotals: {
            quantity: prod.quantity,
            totalCost: prod.totalCost,
            totalSale: prod.totalSale,
            totalFixedCost: prod.totalFixedCost,
          },
        });
      }
    });

  const totalSales: PriceInvoiceInterface[] = [];
  dataByCategories.forEach((elem) => {
    elem.subtotals.totalSale?.forEach((itm) => {
      const idx = totalSales.findIndex(
        (current) => current.codeCurrency === itm.codeCurrency
      );
      idx !== -1
        ? totalSales.splice(idx, 1, {
            ...totalSales[idx],
            amount: totalSales[idx].amount + itm.amount,
          })
        : totalSales.push(itm);
    });
  });

  const totales: {
    quantity: number;
    totalCost: PriceInvoiceInterface;
    totalSales: PriceInvoiceInterface[];
    totalFixedCost?: PriceInvoiceInterface;
  } = {
    quantity: dataByCategories.reduce(
      (total, itm) => total + itm.subtotals.quantity!,
      0
    ),
    totalCost: {
      amount: dataByCategories.reduce(
        (total, itm) => total + itm.subtotals.totalCost!.amount,
        0
      ),
      codeCurrency: dataByCategories[0]?.subtotals.totalCost?.codeCurrency!,
    },
    totalSales: totalSales,
    totalFixedCost: {
      amount: sum(dataByCategories.map((itm)=>itm.products.map((prod)=>prod.totalFixedCost?.amount??0).reduce((a,b)=>a+b,0))) ?? "",
      codeCurrency: dataByCategories[0]?.products[0]?.totalFixedCost?.codeCurrency ?? ""
    }
  };

  let tableTitles = [
    "",
    "Cantidad vendida",
    "Costo unitario ponderado",
    "Costo total",
    "Precio unitario ponderado",
    "Precio total ventas",
    "Costo fijo",
    "Disponibilidad actual",
    "Agrupación",
  ];
  if (!allowRoles(["OWNER", "MANAGER_COST_PRICES"])){
    tableTitles = [
      "",
      "Cantidad vendida",
      "Precio unitario ponderado",
      "Precio total ventas",
      "Disponibilidad actual",
      "Agrupación",
    ];
  }
  const tableData: DataTableInterface[] = [];
  if (orderBy === 1) {
    dataByCategories.sort((a: SelledProductReport, b: SelledProductReport) =>
      a.subtotals.quantity !== undefined && b.subtotals.quantity !== undefined
        ? b.subtotals.quantity - a.subtotals.quantity
        : 0
    );
  } else if (orderBy === 2) {
    dataByCategories.sort((a: SelledProductReport, b: SelledProductReport) =>
      a.subtotals.quantity !== undefined && b.subtotals.quantity !== undefined
        ? a.subtotals.quantity - b.subtotals.quantity
        : 0
    );
  } else {
    dataByCategories.sort(orderByString);
  }
  dataByCategories.forEach((elem) => {
    if (isGroupedByCategory) {
      tableData.push({
        borderTop: true,
        payload: {
          "": <p className="text-base">{elem.salesCategory}</p>,
        },
      });
    }
    //elem.products.sort((a:any, b:any)=>b.totalSale[0].amount - a.totalSale[0].amount).forEach((prod) => {
    elem.products.forEach((prod: any) => {
      const quantityByGroup: (quantity: number) => React.ReactElement | void = (
        quantity
      ) => {
        if (!!prod.enableGroup) {
          const rest = quantity % (prod.groupConvertion??1);
          return (
            <div className="flex-col">
              <div>
                {`${Math.trunc(quantity / (prod.groupConvertion??1))} ${
                  prod.groupName ?? ""
                }`}
              </div>
              {rest !== 0 && (
                <p>{"(+" + rest + translateMeasure(prod.measure ?? "") + ")"}</p>
              )}
            </div>
          );
        }
      };
      tableData.push({
        payload: {
          "": <div className="pl-5 text-gray-600">{prod.name}</div>,
          "Cantidad vendida": prod.quantity,
          "Costo unitario ponderado": formatCurrency(
            prod.totalCost.amount / prod.quantity,
            prod.totalCost.codeCurrency
          ),
          "Costo total": formatCurrency(
            prod.totalCost.amount,
            prod.totalCost.codeCurrency
          ),
          "Precio unitario ponderado": (
            <div className="flex flex-col">
              {prod.salesPrice.map((itm: { amount: number; codeCurrency: string | null | undefined; }, idx: React.Key | null | undefined) => (
                <p key={idx}>
                  {formatCurrency(itm.amount / prod.quantity, itm.codeCurrency)}
                </p>
              ))}
            </div>
          ),
          "Precio total ventas": (
            <div className="flex flex-col">
              {prod.salesPrice.map((itm: { amount: number; codeCurrency: string | null | undefined; }, idx: React.Key | null | undefined) => (
                <p key={idx}>{formatCurrency(itm.amount, itm.codeCurrency)}</p>
              ))}
            </div>
          ),
          "Disponibilidad actual": (
            <div className="flex-col">
              <div className="text-sm">
                {prod.totalQuantity + " " + (prod.measure ?? "")}
              </div>
            </div>
          ),
          Agrupación: (
            <div className="flex-col">
              <div className="text-sm">
                {quantityByGroup(prod.totalQuantity) ?? prod.totalQuantity}
              </div>
            </div>
          ),
          "Costo fijo": (
            <div className="flex-col">
              <p className="text-sm text-nowrap ">
                {`${prod?.totalFixedCost?.amount} ${prod?.totalFixedCost?.codeCurrency}`}
              </p>
            </div>
          ),
        },
      });
    });
    if (isGroupedByCategory) {
      tableData.push({
        borderBottom: true,
        payload: {
          "": "Subtotales",
          "Cantidad vendida": <p className="font-semibold">{elem.subtotals?.quantity}</p>,
          "Costo total": (
            <div className="font-semibold">
              {formatCurrency(
                elem.subtotals.totalCost!.amount,
                elem.subtotals.totalCost!.codeCurrency
              )}
            </div>
          ),
          "Precio total ventas": (
            <div className="font-semibold flex flex-col">
              {elem.subtotals.totalSale?.map((itm, idx) => (
                <p key={idx}>{formatCurrency(itm.amount, itm.codeCurrency)}</p>
              ))}
            </div>
          ),
          "Disponibilidad actual": "",
          Agrupación: "",
          "Costo fijo": (
              <div className="font-semibold flex flex-col text-nowrap">
                {`${elem.subtotals?.totalFixedCost?.amount} ${elem.subtotals?.totalFixedCost?.codeCurrency}`}
              </div>
          ),
        },
      });
    }
  });

  if (dataByCategories.length !== 0)
    tableData.push({
      borderBottom: true,
      payload: {
        "": <p className="font-semibold text-base">Totales</p>,
        "Cantidad vendida": <p className="font-semibold">{totales.quantity}</p>,
        "Costo total": (
          <p className="font-semibold">
            {formatCurrency(
              totales.totalCost.amount,
              totales.totalCost.codeCurrency
            )}
          </p>
        ),
        "Precio total ventas": (
          <div className="flex flex-col font-semibold">
            {totales.totalSales.map((item, idx) => (
              <p key={idx}>{formatCurrency(item.amount, item.codeCurrency)}</p>
            ))}
          </div>
        ),
        "Costo fijo": (
            <div className="font-semibold flex flex-col text-nowrap">
                <p>{`${totales?.totalFixedCost?.amount} ${totales?.totalFixedCost?.codeCurrency}`}</p>
            </div>
        )
      },
    });

  const tableAction: BtnActions[] = [
    {
      title: "Exportar a excel",
      icon: <BsFiletypeXlsx className="text-base" />,
      action: () => setExportModal(true),
    },
  ];
  //--------------------------------------------------------------------------------------------

  const exportAction = (name: string, isGroupedByCategory: boolean) => {
    const priceCurrencies: string[] = [];
    selledReport?.products.forEach((elem: any) => {
      const price = elem.totalSales.find(
        (itm: any) => !priceCurrencies.includes(itm.codeCurrency)
      );
      if (price) {
        priceCurrencies.push(price.codeCurrency);
      }
    });
    if (isGroupedByCategory)
      selledReport?.products?.sort((a: any, b: any) => a.salesCategory.toString().localeCompare(b.salesCategory.toString()))
    const titles: {text: string, row: number }[] = []

    const dataToExport: Record<string, string | number>[] =
      selledReport?.products.map((item: any, index: number) => {
        let data: any = {
          Productos: item.name,
          "Cantidad vendida": item.quantitySales,

        };
        if (allowRoles(["OWNER", "MANAGER_COST_PRICES"])){
          data[`Costo unitario ponderado en ${item.totalCost.codeCurrency}`] = item.totalCost.amount / item.quantitySales
          data[`Costo total en ${item.totalCost.codeCurrency} `] = item.totalCost.amount
          data[`Costo fijo en ${item.totalFixedCost.codeCurrency}`] = item.totalFixedCost.amount
        }

        priceCurrencies.forEach((currency) => {
          const totalSale = item.totalSales.find(
            (price: any) => price.codeCurrency === currency
          );
          data[`Precio unitario ponderado en ${currency}`] = roundTwoDecimals(
            (totalSale?.amount ?? 0) / item.quantitySales
          );
          data[`Precio total venta en ${currency}`] = totalSale?.amount ?? 0;
        });

        const quantityByGroup: (quantity: number) => string | void = (quantity) => {
          if (item.enableGroup) {
            const rest = quantity % (item.groupConvertion??1);
            return `${Math.trunc(quantity / (item.groupConvertion??1))} ${item.groupName ?? ""} ${rest !== 0 ? "(+" + rest + translateMeasure(item.measure ?? "") + ")" : ""
              }`;
          } else return "-"
        };

        data['Disponibilidad actual'] =`${item.totalQuantity + " " + (translateMeasure(item?.measure ?? ""))}`;
        data['Agrupación'] =`${quantityByGroup(item.totalQuantity) ?? item.totalQuantity}`;
        if (isGroupedByCategory && !titles.some((i)=>i.text === item.salesCategory)) {
          titles.push({
            text: item.salesCategory,
            row: index
          })
        }
        return data;
      }) ?? [];
    exportExcel(dataToExport, name, titles);
  };

  let availableFiltersByOrder: ExtendedNomenclator[] = [
    {
      id: 1,
      name: "Cantidad de productos vendidos",
      availableOptions: [
        { name: "De mayor a menor", id: 1 },
        { name: "De menor a mayor", id: 2 },
      ],
      action: (data: number) => (data ? setOrderBy(data) : setOrderBy(0)),
      reset: () => setOrderBy(0),
    },
  ];
  const searching = {
    action: (search: string | null) => {
      setSearchText(search ?? "")
    },
    placeholder: "Buscar",
  };
  return (
    <div>
      <GenericToggle
        changeState={setIsGroupedByCategory}
        // name="groupByCategory"
        currentState={isGroupedByCategory}
        title="Agrupar por categoría"
      />

      <GenericTable
        tableTitles={tableTitles}
        tableData={tableData}
        actions={tableAction}
        loading={isLoading}
        searching={searching}
        orderBy={{ availableFilters: availableFiltersByOrder }}
        columnClassnames={[
          {
            columnName: "Agrupación",
            className: "bg-slate-300"
          },
          {
            columnName: "Disponibilidad actual",
            className: "bg-slate-300"
          },
        ]}
      />

      {exportModal && (
        <Modal state={exportModal} close={setExportModal}>
          <ExportModalContainer
            exportAction={exportAction}
            close={() => setExportModal(false)}
            isGroupedByCategory={isGroupedByCategory}
          />
        </Modal>
      )}
    </div>
  );
}

const ExportModalContainer = ({
  exportAction,
  close,
  isGroupedByCategory,
}: {
  exportAction: Function;
  close: Function;
  isGroupedByCategory: boolean
}) => {
  const { control, handleSubmit } = useForm();
  const submit: SubmitHandler<Record<string, string>> = (data) => {
    exportAction(data.name, isGroupedByCategory);
    close();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        name="name"
        control={control}
        label="Nombre del archivo .xlsx"
        rules={{ required: "Requerido *" }}
      />
      <div className="flex justify-end py-2">
        <Button color="slate-600" name="Aceptar" type="submit" />
      </div>
    </form>
  );
};
