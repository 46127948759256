import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import Breadcrumb, {
  PathInterface,
} from "../../../components/navigation/Breadcrumb";
import GenericTable, {
  DataTableInterface,
} from "../../../components/misc/GenericTable";
import Modal from "../../../components/misc/GenericModal";
import BankAccountOperations from "../reports/BankAccountOperations";

export const BankAccountAnalysis = ({ breadcrumb = true, states = true }) => {
	const navigate = useNavigate();
	const [showModalAccountOperations, setShowModalAccountOperations] = useState(false);

	const tableTitle: string[] = ["Nombre del reporte"];

	const reportsType = [
		{
			id: 2,
			name: "Listado de operaciones",
		}
	];

	if (states){
		reportsType.push({
			id: 1,
			name: "Estados financieros",
		})
	}

	const paths: PathInterface[] = [
	    {
	      name: "Cuentas bancarias",
	      action:()=> navigate("/bank_accounts")
	    },
	    {
	      name:"Análisis",
	    }
	];

	const rowAction = (id: number) => {
	    if (id === 1) {
	      navigate(`/bank_accounts/finance/state`);
	    }
		if (id === 2) {
			setShowModalAccountOperations(true);
		}
  };

  const tableData: DataTableInterface[] = [];
	  reportsType.map(({ id, name }) => {
	    tableData.push({
	      rowId: id,
	      payload: {
	        "Nombre del reporte": name,
	      },
	    });
	});

	return (
		<>
			{breadcrumb && (
				<Breadcrumb
					icon={<ClipboardDocumentListIcon className="h-6 text-gray-500" />}
					paths={paths}		        
				/>
			)}
		    <GenericTable
		        tableData={tableData}
		        tableTitles={tableTitle}
		        rowAction={rowAction} />

			{showModalAccountOperations && (
				<Modal
					state={showModalAccountOperations}
					close={() => setShowModalAccountOperations(false)}
				>
					{<BankAccountOperations />}
				</Modal>
			)}
		</>
	)	
}

export default BankAccountAnalysis;