/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react'
import { BasicType } from '../../interfaces/InterfacesLocal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterOpts, TableContext } from '../misc/GenericTable';
import AsyncComboBox from '../forms/AsyncCombobox';
import ComboBox from '../forms/Combobox';
import MultiSelect from '../forms/Multiselect';
import Input from '../forms/Input';
import DateInput from '../forms/DateInput';
import Select from '../forms/Select';
import Button from '../misc/Button';
import Toggle from '../forms/Toggle';
import { limpiarObjeto } from '../../utils/helpers';
import {BasicNomenclator} from "../../interfaces/ServerInterfaces";
import MultipleFilterBtn from "../misc/MultipleFilterBtn";

interface FilterComponentInt {
    availableFilters: FilterOpts[];
    filterAction: Function;
}

interface FilterComponentInterface {
    filterComponent: FilterComponentInt | undefined;
    filterActived: boolean;
    setFilterActived: React.Dispatch<React.SetStateAction<boolean>>;
    closeModal: Function;
}

const FilterComponent = ({
    filterComponent,
    filterActived,
    setFilterActived,
    closeModal
}: FilterComponentInterface) => {


    const { selectedFilter, setSelectedFilter } = useContext(TableContext);

    const { handleSubmit, control, unregister, watch, reset } = useForm();
    const activeIndex = selectedFilter?.map((item) => item.id);
    const [selectedFilters, setSelectedFilters] = useState<BasicNomenclator[]>([])


    useEffect(() => {
        console.log(selectedFilter!)
        setSelectedFilters(selectedFilter!.map(item => ({
            id: item.id.toString(),
            name: item.id.toString()
        })) ?? [])
        const disabledFiltersCode = filterComponent?.availableFilters
            .filter((_, idx) => !activeIndex?.includes(idx))
            .map((item) => item.filterCode);
        if (selectedFilter?.length === 0 && filterActived) {
            filterComponent?.filterAction(null);
            setFilterActived(false);
        }
        unregister(disabledFiltersCode);
    }, [selectedFilter]);

    const resetForm = () => {

        setSelectedFilter!([])

        filterComponent?.filterAction(null);

        closeModal()

    };

    const onSubmit: SubmitHandler<BasicType> = (data) => {
        const dataToSend = limpiarObjeto(data);

        const findFilterItem = (filterCode: string) => {
            return filterComponent?.availableFilters?.find((item) => {
                if (item.format === "datepicker-range") {
                    return item?.datepickerRange?.some(newItem => newItem?.filterCode === filterCode);
                }
                if (item.format === "price-range") {
                    return item?.priceRange?.some(price => price?.filterCode === filterCode);
                }
                return item.filterCode === filterCode;
            });
        };

        const newSelectedFilter = Object.entries(dataToSend).map(([id, name]) => {
            const filterItem = findFilterItem(id);
            return {
                id,
                name,
                description: filterItem?.format ?? "",
                value: dataToSend[filterItem?.filterCode!]
            };
        });
        filterComponent?.filterAction(dataToSend);
        setSelectedFilter!(newSelectedFilter);
        closeModal();
        setFilterActived(true);
    };
    return (

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-5">
            {/**ACTIONS BUTTONS */}
            <div className="justify-center flex items-center w-full">
                <MultipleFilterBtn
                    btnName='Criterios de búsqueda'
                    selected={selectedFilters}
                    data={filterComponent?.availableFilters.map(item => ({
                        id: item.filterCode,
                        name: item.name
                    })) ?? []}
                    setSelected={(data: BasicNomenclator[], unregistered?: string) => {
                        setSelectedFilters(data);
                        unregistered && unregister(unregistered)
                    }
                    }
                    onClear={() => {

                        if (selectedFilters.length > 0) {
                            reset();
                            setSelectedFilters(() => []);
                        }
                    }}
                />

            </div>


            {/**FILTERS BODY */}
            <div className="flex flex-col flex-1 px-1 gap-3">
                {filterComponent?.availableFilters?.filter(item => selectedFilters.some(s => s.id === item.filterCode)).map((item, idx) =>
                    item.format === "select" && item.asyncData ? (
                        <AsyncComboBox
                            key={idx}
                            name={item.filterCode}
                            control={control}
                            dataQuery={{
                                url: item.asyncData.url,
                                defaultParams: item.asyncData?.defaultParams,
                            }}
                            label={item.name}
                            normalizeData={{
                                id: item.asyncData.idCode,
                                name: item.asyncData.dataCode,
                            }}
                            dependendValue={
                                item.dependentOn
                                    ? {[item.dependentOn]: watch(item.dependentOn)}
                                    : undefined
                            }
                            // defaultItem={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                        />
                    ) : item.format === "select" ? (
                        <ComboBox
                            key={idx}
                            name={item.filterCode}
                            control={control}
                            data={item.data ?? []}
                            label={item.name}
                            defaultValue={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                            // rules={{ required: "Seleccione" }}
                        />
                    ) : item.format === "multiselect" ? (
                        <MultiSelect
                            key={idx}
                            name={item.filterCode}
                            control={control}
                            data={item.data ?? []}
                            label={item.name}
                            //@ts-ignore
                            byDefault={selectedFilter?.find(elem => elem.id === item.filterCode)?.name.join(", ") ?? null}

                            // rules={{ required: "Seleccione" }}
                        />
                    ) : item.format === "input" ? (
                        <Input
                            key={idx}
                            name={item.filterCode}
                            control={control}
                            label={item.name}
                            defaultValue={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                        />
                    ) : item.format === "number" ? (
                        <Input
                            key={idx}
                            name={item.filterCode}
                            control={control}
                            label={item.name}
                            type={"number"}
                            defaultValue={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                        />
                    ) : item.format === "boolean" ? (
                        <Toggle
                            key={idx}
                            name={item.filterCode}
                            control={control}
                            title={item.name}
                            defaultValue={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                        />
                    ) : item.format === "datepicker-range" ? (
                        <div key={idx} className='flex flex-col justify-start items-start w-full'>
                            <h2 className="text-sm font-medium mb-1 text-gray-700 first-letter:uppercase inline-flex items-center">
                                {item.name}
                            </h2>
                            <div
                                key={idx}
                                className="flex gap-1 font-normal items-center w-full"
                            >
                                {item.datepickerRange?.map((rangeDatePicker, index) => {
                                    return (
                                        <div className={'w-full'}>
                                            <DateInput
                                                key={index}
                                                name={rangeDatePicker.filterCode}
                                                label={rangeDatePicker.name}
                                                control={control}
                                                // rules={{ required: "Este campo es requerido" }}
                                                untilToday={rangeDatePicker.isUnitlToday}
                                                includeTime={rangeDatePicker.includingTime}
                                                fromCustom={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                                                defaultValue={selectedFilter?.find(elem => elem.id === rangeDatePicker.filterCode)?.name ?? null}
                                                widthFull={true}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : item.format === "price-range" ? (
                        <div key={idx} className='flex flex-col justify-start items-start w-full'>

                            <h2 className="text-sm font-medium mb-1 text-gray-700 first-letter:uppercase inline-flex items-center">
                                {item.name}
                            </h2>

                            <div
                                key={idx}
                                className="flex gap-1 font-normal items-center"
                            >
                                {item.priceRange?.map((priceRange, index) => {
                                    return index !== 2 ? (
                                        <Input
                                            key={index}
                                            name={priceRange.filterCode}
                                            control={control}
                                            type="number"
                                            label={priceRange.name}
                                            // rules={{ required: "Este campo es requerido" }}
                                            textAsNumber
                                        />
                                    ) : (
                                        <Select
                                            key={index}
                                            name={priceRange.filterCode}
                                            label={priceRange.name}
                                            control={control}
                                            data={
                                                priceRange?.currencies?.map((item) => ({
                                                    id: item,
                                                    name: item,
                                                })) ?? []
                                            }
                                            // rules={{ required: "Este campo es requerido" }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        item.format === "datepicker" && (
                            <div
                                key={idx}
                                className="flex gap-1 font-normal items-center"
                            >
                                <DateInput
                                    name={item.filterCode}
                                    label={item.name}
                                    control={control}
                                    fromCustom={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}
                                    // rules={{ required: "Este campo es requerido" }}
                                    untilToday={item?.isUntilToday}
                                    defaultValue={selectedFilter?.find(elem => elem.id === item.filterCode)?.name ?? null}

                                />
                            </div>
                        )
                    )
                )}
            </div>
            <div className={'gap-5 flex w-full justify-end'}>
                <Button
                    name="Limpiar"
                    textColor="customBlue"
                    outline
                    action={resetForm}
                />
                <Button name="Aplicar" type='submit'/>
            </div>
        </form>

    )
}

export default FilterComponent
