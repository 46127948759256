import {IoAlertCircleOutline} from "react-icons/io5";

interface Props{
    className?: string,
    text: string
}

export default function AlertSquare({className, text}: Props) {
    return (
        <div
            className={`mt-5 flex items-center space-x-2 p-2 border-t-4 border-yellow-500 mb-2 bg-gradient-to-b from-yellow-100 to-yellow-50 rounded-md ${className}`}>
            <div className="flex items-center justify-center h-6 w-6 rounded-full bg-transparent">
                <IoAlertCircleOutline
                    size={24}
                    style={{backgroundColor: "transparent"}}
                    className="text-yellow-500 bg-transparent"
                />
            </div>
            <p className="text-sm text-gray-700">
                {text}
            </p>
        </div>
    )
}

