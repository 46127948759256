/* eslint-disable array-callback-return */
import {useState} from "react";
import {
    ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import GenericTable, {
    DataTableInterface,
} from "../../../components/misc/GenericTable";
import Breadcrumb, {
    PathInterface,
} from "../../../components/navigation/Breadcrumb";
import Modal from "../../../components/misc/GenericModal";
import {} from "../../../interfaces/ServerInterfaces";
import SalesByOrdersModal from "./analysisModals/SalesByOrdersModal";
import AccountingSummaryByOrdersModal from "./analysisModals/AccountingSummaryByOrdersModal";
import SalesByGrossMerchandise from "./analysisModals/SalesByGrossMerchandise";
import MessagingIncomesModal from "./analysisModals/MessagingIncomesModal";
import useServer from "../../../api/useServerMain";

export const EconomicCyclesAnalysis = ({breadcrumb = true}) => {
    //ShowModalsState--------------------------------------------------------------------------------------
    const [showModalSalesByOrders, setShowModalSalesByOrders] = useState(false);
    const {allowRoles} = useServer();

    const [
        showModalAccountingSummaryByOrders,
        setShowModalAccountingSummaryByOrders,
    ] = useState(false);
    const [
        showModalSalesByGrossMerchandise,
        setShowModalSalesByGrossMerchandise,
    ] = useState(false);
    const [
        showModalMessagingIncomes,
        setShowModalMessagingIncomes,
    ] = useState(false);

    //-----------------------------------------------------------------------------------------------------

    //Data to dislay in table ---------------------------------------------------------------------------
    const tableTitle: string[] = ["Nombre del reporte"];

    const reportsType = [
        {
            id: 1,
            name: "Buscador de órdenes",
            visible: allowRoles(["ADMIN", "ANALYSIS_REPORT"])
        },
        {
            id: 2,
            name: "Resumen contable por órdenes",
            visible: allowRoles(["ADMIN", "ANALYSIS_REPORT"])
        },
        {
            id: 3,
            name: "Venta bruta de mercancías",
            visible: allowRoles(["ADMIN", "ANALYSIS_REPORT"])
        },
        {
            id: 4,
            name: "Ingresos de mensajería",
            visible: allowRoles(["ADMIN", "ANALYSIS_REPORT", "MANAGER_SHOP_ONLINE", "MANAGER_SALES", "MANAGER_SHIFT", "MANAGER_BILLING"])
        },
    ];

    const tableData: DataTableInterface[] = [];
    reportsType.map(({id, name, visible}) => {
        if (visible)
            tableData.push({
                rowId: id,
                payload: {
                    "Nombre del reporte": name,
                },
            });
    });

    // Row table actions
    const rowAction = (id: number) => {
        if (id === 1) {
            setShowModalSalesByOrders(true);
        }
        if (id === 2) {
            setShowModalAccountingSummaryByOrders(true);
        }
        if (id === 3) {
            setShowModalSalesByGrossMerchandise(true);
        }
        if (id === 4) {
            setShowModalMessagingIncomes(true);
        }
    };

    //Breadcrumb-----------------------------------------------------------------------------------
    const paths: PathInterface[] = [
        {
            name: "Ciclos económicos",
        },
        {
            name: "Análisis",
        },
    ];

    return (
        <>
            {breadcrumb && (
                <Breadcrumb
                    icon={<ArrowPathRoundedSquareIcon className="h-6 text-gray-500"/>}
                    paths={paths}
                />
            )}

            <GenericTable
                tableData={tableData}
                tableTitles={tableTitle}
                rowAction={rowAction}
            />

            {showModalSalesByOrders && (
                <Modal
                    state={showModalSalesByOrders}
                    close={() => setShowModalSalesByOrders(false)}
                >
                    {<SalesByOrdersModal/>}
                </Modal>
            )}

            {showModalAccountingSummaryByOrders && (
                <Modal
                    state={showModalAccountingSummaryByOrders}
                    close={() => setShowModalAccountingSummaryByOrders(false)}
                >
                    <AccountingSummaryByOrdersModal/>
                </Modal>
            )}

            {showModalSalesByGrossMerchandise && (
                <Modal
                    state={showModalSalesByGrossMerchandise}
                    close={() => setShowModalSalesByGrossMerchandise(false)}
                >
                    {<SalesByGrossMerchandise/>}
                </Modal>
            )}

            {showModalMessagingIncomes && (
                <Modal
                    state={showModalMessagingIncomes}
                    close={() => setShowModalMessagingIncomes(false)}
                >
                    {<MessagingIncomesModal/>}
                </Modal>
            )}
        </>
    );
};

export default EconomicCyclesAnalysis;
