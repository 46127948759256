/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import {createContext, Fragment, useEffect, useState} from "react";
import {
    ExchangeRatesInterface,
    OnlineClientInterface,
    OrderInterface,
    PaginateInterface,
} from "../../../interfaces/ServerInterfaces";
import {
    BanknotesIcon,
    ClockIcon,
    CreditCardIcon,
    HomeModernIcon,
    ReceiptPercentIcon,
    TruckIcon,
    UserIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import {getStatusOrderSpanish} from "../../../utils/functions";
import {getColorStatusOrder} from "../../../utils/tailwindcss";
import {
    formatCalendar,
    formatCurrency,
    generatePdf,
    printPdf,
} from "../../../utils/helpers";
import {useAppSelector} from "../../../store/hooks";
import {FaPrint, FaRegFilePdf} from "react-icons/fa";
import MultipleActBtn, {
    BtnActions,
} from "../../../components/misc/MultipleActBtn";
import Modal from "../../../components/misc/GenericModal";
import OrderReportPdf from "../../../reports/OrderReportPdf";
import PaymentContainer from "../../store/orders/PaymentContainer";
import {Tooltip as ReactTooltip} from "react-tooltip";
import useServer from "../../../api/useServerMain";
import BillingReportPdf from "../../../reports/BillingReportPDF";
import {FaArrowRotateRight, FaEye} from "react-icons/fa6";
import useServerOnlineClients from "../../../api/useServerOnlineClients";
import EditOnlineClientContainer from "../../onlineClients/editClient/EditOnlineClientContainer";
import {sum} from "lodash";

interface Detail {
    item: OrderInterface | null;
    updateOrderState: (order: OrderInterface) => void;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

interface ClientContex {
    getMainClientInfo: Function;
    isFetching: boolean;
    clientOrders: OrderInterface[];
    isLoading: boolean;
    isEdit: boolean;
    isGetClient: boolean;
    paginateAssociatedOrders: PaginateInterface;
    setAllOrderState: Function;
    allOrdes: OrderInterface[];
    editOrder: Function;
    fetchingOrder: boolean;
    updateAllOrderState: Function;
    getClient: (id: number | string) => Promise<void>;
    clientServer: OnlineClientInterface | null;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientContex = createContext<Partial<ClientContex>>({});

const Details = ({item: order, updateOrderState}: Detail) => {
    const {business} = useAppSelector((state) => state.init);
    const [paymentModal, setPaymentModal] = useState(false);
    const [client, setClient] = useState<number | null>(null);

    const {
        editClient,
        deleteClient,
        paginateAssociatedOrders,
        isLoading,
        getMainClientInfo,
        isFetching,
        clientOrders,
        getClient,
        client: clientServer,
        isEdit,
        isGetClient,
    } = useServerOnlineClients();


    const itemsMultiBtn: BtnActions[] = [
        {
            title: "Generar factura",
            icon: <FaRegFilePdf className="h-5 text-gray-500"/>,
            action: () => {
                // reportDowloadHandler("Factura", "bill_pos", business!, item),
                generatePdf(
                    //@ts-ignore
                    OrderReportPdf({order: order, business}),
                    "Factura"
                );
            },
        },
        {
            title: "Imprimir factura",
            icon: <FaPrint className="h-5 text-gray-500"/>,
            action: () => {
                printPdf(
                    BillingReportPdf({order: order, business, reportType: "billing"}),
                    "Factura"
                );
            },
        },

    ];

    if (!!order?.client) {
        itemsMultiBtn.push({
            title: "Ver cliente",
            icon: isGetClient ? <FaArrowRotateRight
                className={`h-5 animate-spin`}
            /> : <FaEye className="h-5 text-gray-500"/>,

            action: () => {
                setClient(order.client.id);
            },
        })
    }

    // if (
    //   business?.configurationsKey.find((itm) => itm.key === "module_billing")
    //     ?.value === "true" &&
    //   verifyRoles(["ADMIN", "MANAGER_SALES"])
    // ) {
    //   // if (
    //   //   (!item?.paidAt ||
    //   //     (!!item?.paidAt && moment().diff(item.paidAt, "hour") <= 24)) &&
    //   //   item?.status !== "CANCELLED"
    //   // )
    //     // itemsMultiBtn.push({
    //     //   title: "Registrar pago",
    //     //   icon: <FaMoneyBillWave className="h-5 text-gray-500" />,
    //     //   action: () => setPaymentModal(true),
    //     // });
    // }

    const [subtotalCosts, setSubtotalCosts] = useState<
        {
            currency: string;
            price: number;
        }[]
    >([]);

    useEffect(() => {
        const updatedAux = [...subtotalCosts];

        order?.selledProducts.forEach((product) => {
            const existingItem = updatedAux.find(
                (itm) => itm.currency === business?.costCurrency
            );

            if (existingItem) {
                existingItem.price += product?.totalCost ?? 0;
            } else {
                updatedAux.push({
                    currency: business?.costCurrency! ?? "",
                    price: product?.totalCost! ?? 0,
                });
            }
        });

        setSubtotalCosts(updatedAux);
    }, []);
    console.log(order);
    const Exchange_rates: ExchangeRatesInterface[] =
        order !== null && order?.meta !== null && order.meta !== ""
            ? JSON.parse(order?.meta! as string)?.exchange_rates
            : {exchange_rates: []};

    const {allowRoles: verifyRoles} = useServer();

    const showCost = verifyRoles(["MANAGER_COST_PRICES", "ADMIN"]);

    return (
        <>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 mx-auto">
                <div
                    className={`divide-gray-200 m-auto rounded-lg border px-5 max-w-lg          
                     border-gray-200`}
                >
                    <div className=" mt-8 flex flex-col sm:-mx-6 md:mx-0 w-full">
                        <h5 className="text-lg font-medium text-gray-900 inline-flex items-center gap-10">
                            Orden: #{order?.operationNumber}{" "}
                            {order?.isForTakeAway ? `` : order?.name}
                            {order?.isForTakeAway && (
                                <span className="">
                  <TruckIcon
                      className="text-gray-900  h-5 z-30"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Para llevar"
                  />
                </span>
                            )}
                            {order?.houseCosted && (
                                <span className="">
                  <HomeModernIcon
                      className="h-5 text-green-600 z-30"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Consumo casa"
                  />
                </span>
                            )}
                            {order?.discount === 100 && (
                                <span className="">
                  <ReceiptPercentIcon
                      className="h-5 text-gray-900"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Descuento 100%"
                  />
                </span>
                            )}
                            <ReactTooltip place="top" id="my-tooltip"/>
                            <div className="flex flex-grow justify-end gap-4 py-5">
                                <MultipleActBtn size="s" items={itemsMultiBtn}/>
                            </div>
                        </h5>
                        <h5 className=" font-medium text-gray-900 flex flex-row items-center">
                            <ClockIcon className="text-gray-900 mr-2 h-5"/>
                            Apertura:{" "}
                            {moment().diff(order?.createdAt, "hour") < 24
                                ? moment(order?.createdAt).format("hh:mm:ss A")
                                : moment(order?.createdAt).format("DD/MM hh:mm:ss A")}
                        </h5>
                        <h5 className="font-medium text-gray-900 flex flex-row items-center">
                            <ClockIcon className="text-gray-900 mr-2 h-5"/>
                            Cierre: {formatCalendar(order?.closedDate)}
                        </h5>
                        <h5 className=" font-medium text-gray-900 flex flex-row items-center">
                            <UserIcon className="text-gray-900 mr-2 h-5"/> Dependiente:{" "}
                            {order?.managedBy?.displayName}
                        </h5>
                        <h5 className=" font-medium text-gray-900 flex flex-row items-center ">
                            <BanknotesIcon className="text-gray-900 mr-2 h-5"/>
                            Caja:
                        </h5>
                        <h5 className=" font-medium text-sm text-gray-900">
                            Nombre: {order?.salesBy?.displayName}
                        </h5>
                        <h5 className=" font-medium text-sm text-gray-900">
                            Punto de venta: {order?.areaSales?.name}
                        </h5>
                        {/*Envío*/}
                        {order?.shipping && (
                            <div className="flex flex-col gap-1 pt-4">
                                <h5 className="font-medium text-gray-900">Envío:</h5>
                                {Object.values(order?.shipping ?? {}).filter((itm) => !!itm)
                                    .length === 0 ? (
                                    <p className="text-2xl">-</p>
                                ) : (
                                    <>
                                        {(order?.shipping?.firstName ||
                                            order?.shipping?.lastName) && (
                                            <p className="text-sm">{`${order?.shipping?.firstName ?? ""
                                            } ${order?.shipping?.lastName ?? ""}`}</p>
                                        )}
                                        {(order?.shipping?.street_1 || order?.shipping?.street_2) && (
                                            <p className="text-sm">{`${order?.shipping?.street_1 ?? ""
                                            } ${order?.shipping?.street_2 ?? ""}`}</p>
                                        )}
                                        {order?.shipping?.city && (
                                            <p className="text-sm">{order?.shipping.city}</p>
                                        )}
                                        {order?.shipping?.municipality && (
                                            <p className="text-sm">
                                                {order?.shipping?.municipality.name}
                                            </p>
                                        )}
                                        {order?.shipping?.province && (
                                            <p className="text-sm">{order?.shipping?.province.name}</p>
                                        )}
                                        {order?.shipping?.country && (
                                            <p className="text-sm">
                                                {order?.shipping?.country?.name ?? ""}
                                            </p>
                                        )}
                                        {order?.shipping?.email && (
                                            <div className="flex flex-col pt-3">
                                                <p className="text-sm font-semibold">
                                                    Dirección de correo electrónico:
                                                </p>
                                                <a
                                                    href={"mail:" + order?.shipping?.email}
                                                    className="text-sm underline text-blue-900"
                                                >
                                                    {order?.shipping?.email}
                                                </a>
                                            </div>
                                        )}
                                        {order?.shipping?.phone && (
                                            <div className="flex flex-col pt-3">
                                                <p className="text-sm font-semibold">Teléfono:</p>
                                                <a
                                                    href={"tel:" + order?.shipping?.phone}
                                                    className="text-sm underline text-blue-900"
                                                >
                                                    {order?.shipping?.phone}
                                                </a>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}

                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                                >
                                    <div
                                        className={classNames(
                                            getColorStatusOrder(order?.status),
                                            "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                                        )}
                                    >
                                        {getStatusOrderSpanish(order?.status)}
                                    </div>
                                </th>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-3 pr-4 text-center text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                >
                                    Cantidad
                                </th>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                >
                                    Precio Total
                                </th>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                >
                                    Precio unitario
                                </th>
                                {!order?.houseCosted && showCost && (
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                                    >
                                        Costo
                                    </th>
                                )}
                            </tr>
                            </thead>

                            <tbody>
                            {order?.selledProducts.map((product, index) => (
                                <Fragment key={index}>
                                    <tr
                                        key={index}
                                        className={`${order?.selledProducts.length === index + 1
                                            ? "border-b border-gray-200"
                                            : ""
                                        } ${product.modifiedPrice ? "bg-yellow-200" : ""}`}
                                    >

                                        {/* Nombre del producto */}
                                        <td
                                            className={`pl-4 text-sm sm:pl-6 md:pl-0 ${product?.status === "REMOVED" ? "line-through" : ""
                                            }`}
                                        >
                                            <div className="font-medium text-gray-900 inline-flex gap-3 items-center">
                                                <div className="flex flex-col">
                                                    <p>{product?.name}</p>{" "}
                                                    {product?.variation && (
                                                        <p className="text-xs text-gray-500">
                                                            {product?.variation.name}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </td>

                                        {/* Cantidad */}
                                        <td
                                            className={`py-1 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-6 md:pr-0 ${product.status === "REMOVED" && "line-through"
                                            }`}
                                        >
                                            {"x" + product?.quantity}
                                        </td>

                                        {/* Precio Total */}
                                        <td
                                            className={`py-1 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0 ${product.status === "REMOVED" && "line-through"
                                            }`}
                                        >
                                            {formatCurrency(
                                                product?.priceTotal?.amount,
                                                product.priceTotal?.codeCurrency
                                            )}
                                        </td>

                                        {/* Precio unitario */}
                                        <td
                                            className={`py-1 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0 ${product.status === "REMOVED" && "line-through"
                                            }`}
                                        >
                                            {formatCurrency(
                                                product?.priceUnitary?.amount,
                                                product.priceTotal?.codeCurrency
                                            )}
                                        </td>

                                        {/* Costo */}
                                        {!order?.houseCosted && showCost && (
                                            <td
                                                className={`py-1 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0 ${product.status === "REMOVED" && "line-through"
                                                }`}
                                            >
                                                {formatCurrency(
                                                    product.totalCost,
                                                    business?.costCurrency ?? ""
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                    {product?.addons?.map((addons, indexAddons) => (
                                        <tr
                                            key={indexAddons}
                                            className={`${product.addons.length === indexAddons + 1
                                                ? "border-b border-dotted border-gray-200"
                                                : ""
                                            }`}
                                        >
                                        <td
                                                className={`pl-4 pr-3 text-sm sm:pl-6 md:pl-0 ${addons.status === "REMOVED" ? "line-through" : ""
                                                }`}
                                            >
                                                <div className="font-medium text-teal-500">
                                                    {"+(x" + addons.quantity + ")" + " " + addons.name}
                                                </div>
                                            </td>

                                            <td
                                                className={`py-1 pl-3 pr-4 text-right text-sm text-teal-500 sm:pr-6 md:pr-0 ${addons.status === "REMOVED" && "line-through"
                                                }`}
                                            >
                                                {formatCurrency(
                                                    addons.priceUnitary?.amount,
                                                    addons.priceUnitary?.codeCurrency
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}
                            <tr className={"border-b border-gray-200"}>
                                {/* Nombre del producto */}
                                <td
                                    className={`pl-4 text-sm sm:pl-6 md:pl-0`}
                                >
                                    <div className="font-bold text-gray-900 inline-flex gap-3 items-center">
                                        <div className="flex flex-col">
                                            Total:
                                        </div>
                                    </div>
                                </td>

                                {/* Cantidad */}
                                <td
                                    className={`py-1 pl-3 pr-4 text-center text-sm text-gray-500 sm:pr-6 md:pr-0`}
                                >
                                    {"x" + sum(order?.selledProducts.map(p => p.quantity))}
                                </td>

                            </tr>
                            {!order?.houseCosted && order?.discount !== 100 && (
                                <tr className="">
                                    <td className="py-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">
                                            Importe:
                                        </div>
                                    </td>

                                    {/* Subtotal de precio total */}
                                    <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0">
                                        {order?.prices.map((itemPrice, index) => (
                                            <p className="font-medium" key={index}>
                                                {formatCurrency(itemPrice.price,
                                                    itemPrice?.codeCurrency
                                                )}
                                                {/* {formatCurrency(
                            item?.discount !== null && item?.taxes !== null
                              ? itemPrice.price -
                              itemPrice.price * (item?.discount / 100) +
                              item?.taxes.amount
                              : itemPrice.price -
                              itemPrice.price * (item?.discount / 100),
                            itemPrice?.codeCurrency
                          )} */}
                                            </p>
                                        ))}
                                    </td>

                                    {/* Subtotal de precio unitario */}
                                    <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"></td>

                                    {/* Subtotal de costo */}
                                    {!order?.houseCosted && showCost && (
                                        <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0">
                                            <p className="font-medium">
                                                {subtotalCosts.map((element, index) => (
                                                    <p className="font-medium" key={index}>
                                                        {formatCurrency(element.price, element.currency)}
                                                    </p>
                                                ))}
                                            </p>
                                        </td>
                                    )}
                                </tr>
                            )}
                            {!order?.houseCosted && order?.discount ? (
                                <tr className="">
                                    <td className="py-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">
                                            Descuento:
                                        </div>
                                    </td>
                                    <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                        {order?.prices.map((itemPrice, index) => (
                                            <p key={index}>
                                                -{" "}
                                                {formatCurrency(
                                                    order?.discount !== null && order?.taxes !== null
                                                        ? itemPrice.price * (order?.discount / 100) +
                                                        order?.taxes?.amount
                                                        : itemPrice.price * (order?.discount / 100),
                                                    itemPrice?.codeCurrency
                                                )}{" "}
                                                ({order?.discount}%)
                                            </p>
                                        ))}
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                            {order?.taxes && (
                                <tr className="">
                                    <td className="pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">Taxes:</div>
                                    </td>

                                    <td className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                        {formatCurrency(
                                            order?.taxes?.amount,
                                            order.taxes.codeCurrency
                                        )}
                                    </td>
                                </tr>
                            )}
                            {order?.shippingPrice && (
                                <tr>
                                    <td className="pt-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">Envíos:</div>
                                    </td>

                                    <td className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                        {formatCurrency(
                                            order?.shippingPrice?.amount,
                                            order?.shippingPrice?.codeCurrency
                                        )}
                                    </td>
                                </tr>
                            )}
                            {order?.couponDiscountPrice && (
                                <tr>
                                    <td className="pt-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">
                                            Descuentos por cupones:
                                        </div>
                                    </td>

                                    <td className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                        {"-" +
                                            formatCurrency(
                                                order?.couponDiscountPrice?.amount,
                                                order?.couponDiscountPrice?.codeCurrency
                                            )}
                                    </td>
                                </tr>
                            )}
                            {!!order?.tipPrice ? (
                                <tr className="">
                                    <td className="pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">
                                            Propinas:
                                        </div>
                                    </td>

                                    <td className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                        {formatCurrency(
                                            order?.tipPrice?.amount,
                                            order?.tipPrice?.codeCurrency
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                            {!!order?.commission && (
                                <tr>
                                    <td className="pt-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">
                                            Comisiones:
                                        </div>
                                    </td>

                                    <td className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                        {order?.prices.map((itemPrice, index) => (
                                            <p key={index}>
                                                {" "}
                                                {formatCurrency(
                                                    itemPrice.price * (order?.commission / 100),
                                                    itemPrice?.codeCurrency
                                                )}{" "}
                                                ({order?.commission}%)
                                            </p>
                                        ))}
                                    </td>
                                </tr>
                            )}
                            {order?.orderModifiers && order.orderModifiers.length !== 0 && (
                                <>
                                    {order?.orderModifiers.map((modified, index) => {
                                        return (
                                            <>
                                                <tr className="border-y  border-gray-200">
                                                    <td className="pt-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                                        <div className="font-medium text-gray-900">
                                                            {modified?.showName}:
                                                        </div>
                                                    </td>

                                                    <td className=" py-1 pl-3 pr-4 text-right text-sm font-medium text-gray-900 sm:pr-6 whitespace-pre-wrap md:pr-0">
                                                        <div className="flex flex-col">
                                                            <div>
                                                                <div className="relative">
                                    <span
                                        className=" inline-flex gap-2 justify-end"
                                        key={index}
                                    >
                                      {formatCurrency(
                                          modified?.amount,
                                          modified?.codeCurrency
                                      )}
                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <ReactTooltip place="top" id="my-tooltip"/>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </>
                            )}
                            <tr className="">
                                <td className="py-1 pt-2 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                    <div className="font-bold  text-gray-900">Subtotal:</div>
                                </td>

                                {/* Espacio vacio */}
                                {/* <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0">

                  </td> */}

                                <td className="py-1 pl-3 pr-4 flex flex-col text-right text-sm font-medium text-gray-900 sm:pr-6 md:pr-0"></td>

                                <td className="py-1 pl-3 pr-4 flex flex-col text-right text-sm font-medium text-gray-900 sm:pr-6 md:pr-0">
                                    {order?.houseCosted
                                        ? formatCurrency(order?.totalCost, business?.costCurrency)
                                        : order?.totalToPay.map((itm, idx) => (
                                            <p key={idx}>
                                                {formatCurrency(itm?.amount, itm?.codeCurrency)}
                                            </p>
                                        ))}
                                </td>
                            </tr>

                            {order?.currenciesPayment &&
                                order?.currenciesPayment.length !== 0 && (
                                    <tr className="border-t  border-gray-200">
                                        <td className="pt-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                            <div className="font-bold text-gray-900">
                                                Total pagado:
                                            </div>
                                        </td>

                                        <td className=" py-1 pl-3 pr-4 text-right text-sm font-medium text-gray-900 sm:pr-6 whitespace-pre-wrap md:pr-0">
                                            <div className="flex flex-col">
                                                {order?.currenciesPayment.map(
                                                    (currencyPayment, index) => {
                                                        return (
                                                            <div>
                                                                <div className="relative">
                                    <span
                                        className=" inline-flex gap-2 justify-end"
                                        key={index}
                                    >
                                      {formatCurrency(
                                          currencyPayment?.amount,
                                          currencyPayment?.codeCurrency
                                      )}
                                        {currencyPayment?.paymentWay ===
                                        "CASH" ? (
                                            <BanknotesIcon
                                                className="h-5"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Efectivo"
                                            />
                                        ) : (
                                            <CreditCardIcon
                                                className="h-5"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Transferencia"
                                            />
                                        )}
                                        {order?.amountReturned &&
                                            currencyPayment?.codeCurrency !==
                                            order?.amountReturned?.codeCurrency &&
                                            Exchange_rates?.length >
                                            0 && (
                                                // (item?.amountReturned && currencyPayment.codeCurrency !== item.amountReturned.codeCurrency && item?.meta?.Exchange_rates) && (
                                                <span className="absolute top-0 left-32 whitespace-nowrap">
                                            {`(1 ${currencyPayment?.codeCurrency
                                            } = ${Exchange_rates.find(
                                                (curr) =>
                                                    curr.code ===
                                                    currencyPayment?.codeCurrency
                                            )?.exchangeRate
                                            } ${business?.mainCurrency})`}
                                          </span>
                                            )}
                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </td>

                                        <ReactTooltip place="top" id="my-tooltip"/>
                                    </tr>
                                )}


                            {order?.amountReturned && (
                                <tr className="border-b border-gray-200">
                                    <td className="py-1 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                                        <div className="font-bold  text-gray-900">Cambio:</div>
                                    </td>
                                    <td className="py-1 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0">
                                        {formatCurrency(
                                            order?.amountReturned?.amount,
                                            order?.amountReturned?.codeCurrency
                                        )}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {paymentModal && (
                <Modal state={paymentModal} close={setPaymentModal} size="m">
                    <PaymentContainer
                        order={order!}
                        closeModal={() => setPaymentModal(false)}
                        updState={updateOrderState}
                    />
                </Modal>
            )}

            <ClientContex.Provider
                value={{
                    getMainClientInfo,
                    isFetching,
                    clientOrders,
                    paginateAssociatedOrders,
                    isLoading,
                    getClient,
                    clientServer,
                    isEdit,
                    isGetClient,
                }}
            >
                {client && (
                    <Modal state={!!client} close={() => setClient(null)} size="l">
                        <EditOnlineClientContainer
                            clientId={client!}
                            editClient={(id: number, data: any) => {
                                editClient(id, data, setClient);
                            }}
                            deleteClient={deleteClient}
                            isFetching={isFetching}
                            isLoading={isLoading}
                            wichContextUse="details"
                        />
                    </Modal>
                )}
            </ClientContex.Provider>
        </>
    );
};

export default Details;
