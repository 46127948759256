import React, {useContext, useEffect, useMemo, useState} from "react";
import TextArea from "../../../components/forms/TextArea";
import Input from "../../../components/forms/Input";
import Button from "../../../components/misc/Button";
import { FaArrowRotateRight } from "react-icons/fa6";
import { SubmitHandler, useForm } from "react-hook-form";
import { DetailProductContext } from "../DetailProductContainer";
import useServer from "../../../api/useServerMain";
import Select from "../../../components/forms/Select";
import {useAppSelector} from "../../../store/hooks";
import {SelectInterface} from "../../../interfaces/InterfacesLocal";
import GenericTable from "../../../components/misc/GenericTable";
import useProduct from "../../../hooks/useProduct";
import useServerProducts, {useServerProduct} from "../../../api/useServerProducts";
import Modal from "../../../components/modals/GenericModal";
import {BasicNomenclator, TransformationRuleInterface} from "../../../interfaces/ServerInterfaces";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import MultiSelect from "../../../components/forms/Multiselect";
import {BtnActions} from "../../../components/misc/MultipleActBtn";
import {PlusIcon} from "@heroicons/react/24/outline";
import ProductTypeBadge from "../../../components/misc/badges/ProductTypeBadge";
import {translateMeasure} from "../../../utils/translate";
import {formatCurrency} from "../../../utils/helpers";
import {TrashOutline} from "heroicons-react";
import useServerArea from "../../../api/useServerArea";

const TransformationRules = () => {
    const { product, updateProduct } = useContext(DetailProductContext);
    const {allTransformationRules, getAllTransformationRules, isLoading} = useServerProduct()
    const [transformationRuleModal, setTransformationRuleModal] = useState<{
        state: boolean;
        current?: number;
    }>({state: false});

    useEffect(() => {
        getAllTransformationRules(product?.id!, {page: 1});
    }, []);

    console.log(allTransformationRules)

    const tableTitles = ["Producto destino", "U/M Origen", "U/M Destino", "Eqivalencia", "Áreas"];

    const tableData = allTransformationRules?.map(item => ({
        rowId: item.id,
        boldRow: item.id === 0,
        deletedRow: item.id === -1,
        payload: {
            "Producto destino": item.finalProduct.name,
            "U/M Origen": translateMeasure(item.baseProduct.measure),
            "U/M Destino": translateMeasure(item.finalProduct.measure),
            "Eqivalencia": item.unitaryEquivalentFraction,
            "Áreas": (
                <div>
                    {item.areas.map((area) => (
                        <p key={area.id} className="mr-2">
                            {area.name}
                        </p>
                    ))}
                </div>
            ),
        }
    })) ?? [];


    const onSubmit: SubmitHandler<Record<string, any>> = (data) => {
        const { printableLabelFront, printableLabelBack, expirationInDaysAfterProduction, productionOriginAreaId} = data;

        const dataToSend = {
            printableLabelFront,
            printableLabelBack,
            expirationInDaysAfterProduction,
            productionOriginAreaId,
        };

        updateProduct!(product?.id, dataToSend);
    };

    const actions: BtnActions[] = [
        {
            title: "Nuevo producto",
            action: () => setTransformationRuleModal({
                state: true,
                current: undefined
            }),
            icon: <PlusIcon className="h-5"/>,
        }
    ]

    return (
        <div className="p-7 flex flex-col border border-slate-300 rounded-md h-auto justify-start">
            <GenericTable
                tableTitles={tableTitles}
                tableData={tableData}
                loading={isLoading}
                actions={actions}
                rowAction={(rowId: number) => setTransformationRuleModal({state: true, current: rowId})}
            />
            {transformationRuleModal.state && (
                <Modal
                    state={transformationRuleModal.state}
                    close={() => setTransformationRuleModal({state: false})}
                >
                    <TransformationRulesModal
                        transformationRule={allTransformationRules.find(
                            (item) => item.id === transformationRuleModal.current
                        )}
                        close={() => setTransformationRuleModal({state: false})}
                        getAllTransformationRules={getAllTransformationRules}
                    />
                </Modal>
            )}
        </div>
    );
};

export default TransformationRules;

interface ModalProps {
    transformationRule?: TransformationRuleInterface,
    close: () => void
    getAllTransformationRules: (productId: number, params?: any) => void
}
function TransformationRulesModal({ transformationRule, close, getAllTransformationRules }: ModalProps) {
    const { register, handleSubmit, control } = useForm();
    const { product } = useContext(DetailProductContext);
    const {newTransformationRule, isLoading, deleteTransformationRule, updateTransformationRule} = useServerProducts();
    const { areas } = useAppSelector((state) => state.nomenclator);
    const { getAllAreas, allAreas } = useServerArea();

    useEffect(() => {
        getAllAreas({ type: "STOCK", all_data: true, inAllMyBusiness: true }, true);
    }, []);

    const areasSelect: SelectInterface[] =
        allAreas.length > 0? allAreas?.filter(i=>i.type === "STOCK").map((a) => ({
            id: a?.id ?? null,
            name: a?.name ?? "",
        })) ?? []
        :
        areas.filter(i=>i.type === "STOCK").map((a) => ({
            id: a?.id ?? null,
            name: a?.name ?? "",
        })) ?? [];

    const onSubmit: SubmitHandler<Record<string, any>> = (data: any) => {
        if (!!transformationRule) {
            updateTransformationRule(transformationRule!.id, data, () => {
                close()
                getAllTransformationRules(product!.id, {page: 1})
            })

        }else {
            newTransformationRule(data, product!.id, ()=>{
                close()
                getAllTransformationRules(product!.id, {page: 1})
            })
        }

    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="flex justify-between items-center text-center w-full pe-6">
                {!!transformationRule ? (
                    <h5 className="text-gray-600 text-lg font-bold">Editar regla de transformacion</h5>
                ) : (

                    <h5 className="text-gray-600 text-lg font-bold">Nueva regla de transformacion</h5>
                )}
                {!!transformationRule && (
                    <Button
                        color="red-400"
                        icon={<TrashOutline className="h-5 text-gray-500" onPointerEnterCapture={undefined}
                                            onPointerLeaveCapture={undefined} stroke={"#f87171"}/>}
                        action={() => deleteTransformationRule(transformationRule.id, () => {
                            close()
                            getAllTransformationRules(product!.id, {page: 1})
                        })}
                        outline
                    />
                )}
            </div>
            <AsyncComboBox
                label="Producto"
                name="finalProductId"
                control={control}
                // id : cuba
                dataQuery={{
                    url: "/administration/product",
                }}
                normalizeData={{id: "id", name: "name"}}
                defaultValue={transformationRule?.finalProduct.id ?? undefined}
                defaultItem={transformationRule ? {
                    id: transformationRule.finalProduct.id,
                    name: transformationRule.finalProduct.name
                } : undefined}
                rules={{
                    required: {
                        value: true,
                        message: "Este campo es requerido",
                    },
                }}
            />
            <Input
                label="Fraccion unitaria de equivalencia"
                type="number"
                {...register("unitaryEquivalentFraction", {required: true})}
                control={control}
                defaultValue={transformationRule?.unitaryEquivalentFraction ?? ""}
            />
            <MultiSelect
                name={"listAreas"}
                label={"Áreas"}
                data={areasSelect}
                control={control}
                byDefault={transformationRule?.areas.map(area => area.id) ?? []}
                defaultValue={transformationRule?.areas.map(area => area.id) ?? []}
            />
            <div className="flex justify-end gap-2">
                <Button
                    name={`${transformationRule ? "Actualizar" : "Crear"}`}
                    color="slate-600"
                    type="submit"
                    loading={isLoading}
                />
            </div>
        </form>
    );

}