import { ProductInterface } from '../interfaces/ServerInterfaces'
import DocumentPage from "../components/pdf/DocumentPage";


import {
    View, Text, Image, StyleSheet

} from "@react-pdf/renderer";

import APIMediaServer from '../api/APIMediaServer';
import { blobToDataURL } from './helpers/commons';
import { translateMeasure } from '../utils/translate';

interface AllProductsPdfInterface {
    products: ProductInterface[];
    withDisp: boolean;
    withPrices: boolean;
    withCategory: boolean;
    withDescription: boolean;
    businessName: string;
}

//Styles
const tableStyles = StyleSheet.create({
    tableTitle: {
        marginTop: 10,
        fontSize: 13,
        fontWeight: "semibold",
        textDecoration: "underline",
    },
    table: {
        marginTop: 5,
        paddingBottom: 10,
        gap: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    productCard: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: 2,
        marginTop: 10,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "solid",
        borderBottomColor: "#7a7a7a",
        borderBottomWidth: 1,
    },
    rowHeader: {
        // borderBottom: "solid",
        // borderBottomColor: "#9ca3af",
        // borderBottomWidth: 2,
        backgroundColor: "#000000",
        color: "#ffffff",
        fontWeight: 500
    },
    normalText: {
        fontSize: 12,
        fontWeight: 500,
    },
    bodyText: {
        fontSize: 6,
    },

    productImage: {
        height: 100,
        width: 80,
        objectFit: "contain",
        paddingLeft: 0.9,
        borderRadius: "15%",
    },
});

const AllProductsPdf = ({
    products,
    withDisp = true,
    withPrices = true,
    withCategory = true,
    withDescription = true,
    businessName
}: AllProductsPdfInterface) => {


    const getProductImage = async (logoId: number) => {
        try {
            const response = await APIMediaServer.get("/files/" + logoId, {
                responseType: "blob",
            });
            return await blobToDataURL(response.data);
        } catch (error) {
            // console.error(er ror);
            return require("../assets/image-default.jpg");
        }
    };

    const groupedNames = products.reduce<{ [key: string]: ProductInterface[] }>((acc, item) => {
        const firstLetter = item.name[0].toUpperCase();
        if (!acc[firstLetter]) {
            acc[firstLetter] = [];
        }
        acc[firstLetter].push(item);
        return acc;
    }, {});
    return (
        <DocumentPage>
            <View style={{ textAlign: 'left', marginBottom: 20, fontSize: 20, fontWeight: 700 }} wrap={false}>
                <Text>{businessName}: Catálogo de productos</Text>
            </View>

            <View style={tableStyles.table}>
                {
                    Object.keys(groupedNames).map((letter) => (
                        <div key={letter}>
                            <Text style={{ textAlign: 'left' }}>
                                {letter}
                            </Text>
                            {groupedNames[letter].map((prod, idx) => {

                                const quantityByGroup: (quantity: number) => string | void = (
                                    quantity
                                ) => {
                                    if (prod.enableGroup) {
                                        const rest = quantity % (prod.groupConvertion ?? 1);
                                        return `${Math.trunc(quantity / (prod.groupConvertion ?? 1))} ${prod.groupName ?? ""
                                            } ${rest !== 0
                                                ? "(+" + rest + translateMeasure(prod.measure ?? "") + ")"
                                                : ""
                                            }`;
                                    } else {
                                        return ""
                                    }

                                };


                                return (
                                    <View
                                        key={idx}
                                        style={
                                            {
                                                ...tableStyles.productCard,
                                            }
                                        }
                                    >
                                        <div>
                                            {/* Imagen */}
                                            <View style={{ width: "30%" }}>
                                                <Image
                                                    style={tableStyles.productImage}
                                                    src={
                                                        prod.images.length > 0
                                                            ? getProductImage(prod.images[0]?.id!)
                                                            : require("../assets/image-default.jpg")
                                                    }
                                                />
                                            </View>
                                        </div>

                                        <div>
                                            {/* Informacion del producto  */}
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", marginLeft: 25, marginTop: 8 }}>
                                                {/* Nombre */}
                                                <Text style={{ textAlign: 'left', paddingBottom: 4, fontSize: 16, fontWeight: 700 }}>
                                                    {prod.name}
                                                </Text>

                                                {/* Categoría de venta*/}
                                                {withCategory && (
                                                    <View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                        <View>
                                                            <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                Categoría de venta:{"       " + (
                                                                prod.salesCategory?.name? prod.salesCategory.name : "-"
                                                            )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )}
                                                {/* Descripción */}
                                                {withDescription && (
                                                    <View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                        <View>
                                                            <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                Descripción:{"       " + (
                                                                    prod.description || "-"
                                                            )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )}

                                                {/* Disponibilidad */}
                                                {withDisp && (
                                                    <View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                        <View>
                                                            <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                Disponibilidad:{"       " + (
                                                                    prod.stockLimit
                                                                        ? `${prod.totalQuantity} ${translateMeasure(prod?.measure)}`
                                                                        : "Ilimitado"
                                                                )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )}

                                                {/* Agrupación */}
                                                {withDisp && prod.enableGroup &&  (
                                                    <View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                        <View>
                                                            <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                Agrupación:{"           " + (
                                                                    quantityByGroup(prod.totalQuantity)
                                                                )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                )}


                                                {/* Precios */}
                                                {
                                                    withPrices && (
                                                        <View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                            <View>
                                                                <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                    Precios:{"                 " + prod.prices.map(price => `${price.price} ${price.codeCurrency}`).join(" ")}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )
                                                }


                                                {/* Descripción */}
                                                {
                                                    !!prod.description && (
                                                        < View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                            <View>
                                                                <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                    Descripción:{"          " + (prod.description ?? "")}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )
                                                }

                                            </View>
                                        </div>

                                    </View>
                                )
                            })}
                        </div>
                    ))}
            </View >


        </DocumentPage >
    )
}

export default AllProductsPdf
