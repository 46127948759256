/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useRef, useState} from "react";
import Button from "../../../../components/misc/Button";
import Input from "../../../../components/forms/Input";
import TextArea from "../../../../components/forms/TextArea";
import AsyncComboBox from "../../../../components/forms/AsyncCombobox";
import {RegisterContext} from "../AllRegistersList";
import {EditContextBilling} from "../registerDetailsTabs/RegisterDetailsTab";
import Toggle from "../../../../components/forms/Toggle";
import {useAppSelector} from "../../../../store/hooks";
import {SelectInterface} from "../../../../interfaces/InterfacesLocal";
import Select from "../../../../components/forms/Select";
import CurrencyAmountInput from "../../../../components/forms/CurrencyAmountInput";
import DateInput from "../../../../components/forms/DateInput";
import GenericTable, {DataTableInterface} from "../../../../components/misc/GenericTable";
import useServerOnlineClients from "../../../../api/useServerOnlineClients";
import {AddressInterface, OnlineClientInterface} from "../../../../interfaces/ServerInterfaces";
import {PlusIcon} from "@heroicons/react/24/outline";
import Modal from "../../../../components/misc/GenericModal";

export const ShippingStep = ({onSubmitForm}: { onSubmitForm: Function }) => {
    const {
        control,
        watch,
        setCurrentStep,
        currentStep,
        setValue,
        getValues,
        isSubmit,
        setOpenPayModal,
        isFetching,
        shippingRegions,
    } = useContext(RegisterContext);
    const {availableCurrencies, configurationsKey} = useAppSelector(
        (state) => state.init.business!
    );
    const {deliverymans} = useAppSelector(
        (state) => state.nomenclator!
    );

    const {getAllClients, allClients} = useServerOnlineClients()

    const {defaultValues, editMode} = useContext(EditContextBilling);

    const [showClientAddressModal, setShowClientAddressModal] = useState(false)

    const [loadingHelper, setLoadingHelper] = useState(false);
    const isPreBilling = watch!("registerType") === "PRE-BILLING";

    const shipingWatching = watch!("shippingType");
    const isPickUp = shipingWatching === "pickUp";
    const municipalityId = watch!("shipping.municipalityId");
    const [selectDelivers, setSelectDelivers] = useState<SelectInterface[]>([])
    const currencies =
        availableCurrencies.map((item) => item.code) ?? [];

    const [shippingRegionSelect, setShippingRegionSelect] = useState<SelectInterface[]>([])
    const deliveryType = configurationsKey.find((conf) => conf.key === "delivery_type")!.value
    const deliveryFixedPrice = configurationsKey.find((conf) => conf.key === "amount_delivery_fixed")!.value
    const deliveryCurrency = configurationsKey.find((conf) => conf.key === "general_cost_currency")!.value

    const defaultShippingPrice = useRef({
        amount: 0,
        codeCurrency: deliveryCurrency
    })
    useEffect(() => {
        getAllClients({all_data: true})
    }, []);

    useEffect(() => {
        if (editMode) {
            setValue!("shipping.countryId", defaultValues?.shipping?.country?.id)
            setValue!("shipping.provinceId", defaultValues?.shipping?.province?.id)
            setValue!("shipping.municipalityId", defaultValues?.shipping?.municipality?.id)
            setValue!("shippingRegionId", defaultValues?.shippingRegion?.id)

            defaultShippingPrice.current = {
                amount: defaultValues?.shippingPrice?.amount!,
                codeCurrency: defaultValues?.shippingPrice?.codeCurrency!
            }

        }
        if (deliveryType === 'FIXED') {
            defaultShippingPrice.current = {
                amount: parseFloat(deliveryFixedPrice),
                codeCurrency: deliveryCurrency
            }
        }

    }, []);

    useEffect(() => {
        if (deliveryType === 'BYREGION') {
            if (shippingRegions) {
                const region: SelectInterface[] = shippingRegions
                    .filter(item => item.municipality.id === municipalityId)
                    .map(item => {
                        return {
                            id: item.id,
                            name: `${item.name} - ${item.price.amount}${item.price.codeCurrency}`
                        }
                    })
                setShippingRegionSelect(region)
            }
        }
    }, [shippingRegions, municipalityId]);

    //Cargar Mensajeros
    useEffect(() => {
        const newSelect: SelectInterface[] = deliverymans?.filter((item) => item.roles?.some((rol) => rol.name === "Repartidor"))
            .map((item) => {
                return {
                    id: item.id,
                    name: item.displayName
                }
            }) ?? []
        setSelectDelivers(newSelect)
    }, [deliverymans])


    const clientId = watch!("clientId");
    const client = allClients.find((item => item.id === clientId));
    const loadClientData = () => {
       setShowClientAddressModal(true)
    };

    return (
        <div className="grid gap-y-6 px-8">
            <form className="min-h-[25rem]">
                    <div className={'flex flex-col'}>
                        <div className={'flex items-center justify-between'}>
                            <h1 className={'font-bold text-lg text-gray-700 mb-2'}>
                                Datos del Receptor:
                            </h1>
                            <div>
                                <Button
                                    name="Direcciones del cliente"
                                    color="white"
                                    textColor="slate-700"
                                    type="button"
                                    action={() => {
                                        loadClientData();
                                    }}
                                    disabled={currentStep === 0}
                                />
                            </div>
                        </div>
                        <div className="flex w-full gap-x-4 pb-4">
                            <div className="flex flex-col gap-2 w-1/2">
                                <div>
                                    <Input
                                        label="Nombre (*)"
                                        name={"shipping.firstName"}
                                        control={control}
                                        disabled={isPickUp}
                                        type="textOnly"
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        defaultValue={editMode && defaultValues?.shipping?.firstName}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Apellidos (*)"
                                        name="shipping.lastName"
                                        control={control}
                                        disabled={isPickUp}
                                        type="textOnly"
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        defaultValue={editMode && defaultValues?.shipping?.lastName}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Teléfono"
                                        name="shipping.phone"
                                        type="number"
                                        textAsNumber
                                        control={control}
                                        disabled={isPickUp}
                                        maxLength={10}
                                        defaultValue={editMode && defaultValues?.shipping?.phone}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Correo electrónico"
                                        name="shipping.email"
                                        control={control}
                                        disabled={isPickUp}
                                        defaultValue={editMode && defaultValues?.shipping?.email}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Código postal"
                                        name="shipping.postalCode"
                                        control={control}
                                        disabled={isPickUp}
                                        type="number"
                                        textAsNumber
                                        maxLength={10}
                                        defaultValue={editMode && defaultValues?.shipping?.postalCode}
                                    />
                                </div>

                                {/*<div className="pt-2">
                                    <Input
                                        label="Localidad"
                                        name="shipping.city"
                                        control={control}
                                        disabled={isPickUp}
                                        // rules={{
                                        //   required: {
                                        //     value: !isPickUp,
                                        //     message: "Este campo es requerido",
                                        //   },
                                        // }}
                                        defaultValue={editMode && defaultValues?.shipping?.city}
                                    />
                                </div>*/}
                            </div>

                            <div className="flex flex-col gap-2 w-1/2">
                                <div>
                                    <Input
                                        label="Calle principal"
                                        name="shipping.street_1"
                                        control={control}
                                        disabled={isPickUp}
                                        // rules={{
                                        //   required: {
                                        //     value: !isPickUp,
                                        //     message: "Este campo es requerido",
                                        //   },
                                        // }}
                                        defaultValue={editMode && defaultValues?.shipping?.street_1}
                                    />
                                </div>

                                <div>
                                    <Input
                                        label="Calle secundaria"
                                        name="shipping.street_2"
                                        control={control}
                                        disabled={isPickUp}
                                        defaultValue={editMode && defaultValues?.shipping?.street_2}
                                    />
                                </div>

                                <div>
                                    <AsyncComboBox
                                        label="País  (*)"
                                        name="shipping.countryId"
                                        control={control}
                                        // id : cuba
                                        dataQuery={{
                                            url: "/public/countries",
                                            defaultParams: {id: 54, all_data: true},
                                        }}
                                        defaultValue={54}
                                        defaultItem={{
                                            id: 54,
                                            name: "Cuba"
                                        }}
                                        normalizeData={{id: "id", name: "name"}}
                                        disabled={isPickUp}
                                        rules={{
                                            required: {
                                                value: (!isPickUp && deliveryType === 'BYREGION'),
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                    />
                                </div>

                                <div>
                                    <AsyncComboBox
                                        name="shipping.provinceId"
                                        label="Provincia"
                                        control={control}
                                        dataQuery={{url: "/public/provinces"}}
                                        normalizeData={{id: "id", name: "name"}}
                                        dependendValue={{
                                            countryId:
                                                watch!("shipping.countryId") ??
                                                getValues!("shipping.countryId"),
                                        }}
                                        rules={{
                                            required: {
                                                value: (!isPickUp && deliveryType === 'BYREGION'),
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        disabled={isPickUp}
                                    />
                                </div>

                                <div>
                                    <AsyncComboBox
                                        name="shipping.municipalityId"
                                        label="Municipio"
                                        control={control}
                                        dataQuery={{url: "/public/municipalities"}}
                                        normalizeData={{id: "id", name: "name"}}
                                        dependendValue={{
                                            provinceId:
                                                watch!("shipping.provinceId") ??
                                                getValues!("shipping.provinceId"),
                                        }}
                                        rules={{
                                            required: {
                                                value: (!isPickUp && deliveryType === 'BYREGION'),
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        disabled={isPickUp || !watch!("shipping.provinceId")}
                                        defaultValue={
                                            editMode && defaultValues?.shipping?.municipality?.id
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <TextArea
                                label="Añadir notas"
                                name="shipping.description"
                                control={control}
                                disabled={isPickUp}
                                defaultValue={
                                    editMode && defaultValues?.shipping?.description
                                }
                            />
                        </div>
                        <div className={'flex flex-col'}>
                            <h1 className={'font-bold text-lg text-gray-700 mb-2'}>
                                Datos del Envío:
                            </h1>
                            <div className={'flex space-x-4'}>
                                <Select
                                    name={'shippingById'}
                                    className={'w-full'}
                                    label={'Mensajero'}
                                    data={selectDelivers}
                                    control={control}
                                    placeholder={'Seleccione un Mensajero'}
                                    defaultValue={editMode ? defaultValues?.shippingBy?.id : null}
                                />
                                <DateInput
                                    name={'deliveryAt'}
                                    control={control}
                                    label={"Fecha de Envío"}
                                    includeTime={true}
                                    defaultValue={editMode? defaultValues?.deliveryAt : Date.now}
                                    flexCol={true}
                                />

                            </div>

                            <div className={'flex space-x-2 mt-2'}>
                                {deliveryType === 'BYREGION' &&
                                    <Select
                                        label={'Región de envío  (*)'}
                                        className={'w-full'}
                                        name={'shippingRegionId'}
                                        data={shippingRegionSelect}
                                        defaultValue={null}
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                        control={control}
                                    />
                                }
                                {deliveryType !== 'BYREGION' &&
                                    <CurrencyAmountInput
                                        name={'shippingPrice'}
                                        currencies={currencies}
                                        disabled={deliveryType === 'FIXED'}
                                        byDefault={defaultShippingPrice.current}
                                        control={control}
                                        placeholder={'Precio'}
                                        label={'Precio de envío'}
                                        rules={{
                                            required: {
                                                value: !isPickUp,
                                                message: "Este campo es requerido",
                                            },
                                        }}
                                    />}


                            </div>
                        </div>
                    </div>
            </form>
            {!editMode && (
                <div className="flex justify-between w-full h-fit gap-3 pt-2">
                    <Button
                        name="Atrás"
                        color="white"
                        textColor="blue-800"
                        outline
                        type="button"
                        action={() => {
                            setCurrentStep!(currentStep! - 1);
                        }}
                        full
                        disabled={currentStep === 0 ? true : false}
                    />
                    <Button
                        name="Registrar"
                        color={watch!("registerType") === "PRE-BILLING"? "slate-700" : "white"}
                        textColor={watch!("registerType") === "PRE-BILLING"? undefined : "blue-800"}
                        outline={watch!("registerType") !== "PRE-BILLING"}
                        //type="submit"
                        full
                        loading={isSubmit && !loadingHelper}
                        disabled={isSubmit}
                        action={onSubmitForm && onSubmitForm}
                    />
                    {watch!("registerType") !== "PRE-BILLING" &&
                        <Button
                            name="Registrar y facturar"
                            color="slate-700"
                            type="submit"
                            full
                            loading={isSubmit && loadingHelper}
                            disabled={isSubmit}
                            action={() => {
                                //Action para abrir el modal de pago directa,mente
                                setOpenPayModal && setOpenPayModal(true);
                                setLoadingHelper(true);
                                onSubmitForm && onSubmitForm(true);
                            }}
                        />
                    }

                </div>
            )}

            {editMode && (
                <div className="flex w-full row-span-2 items-end justify-end">
                    <div className="grid">
                        <Button
                            name={editMode ? "Actualizar" : `Registrar`}
                            color="slate-700"
                            type="submit"
                            full
                            loading={isFetching}
                            disabled={isFetching}
                        />
                    </div>
                </div>
            )}
            {showClientAddressModal && client &&
                <Modal close={setShowClientAddressModal} state={showClientAddressModal} size={"m"}>
                    <AddressSelectModal
                        client={client}
                        close={()=>setShowClientAddressModal(false)}
                    />
                </Modal>
            }
        </div>
    );
};
interface AddressModalProps{
    client: OnlineClientInterface,
    close: () => void
}
function AddressSelectModal({client, close}: AddressModalProps){
    const {
        setValue,
    } = useContext(RegisterContext);
    const serverOnlineClients = useServerOnlineClients()
    const {getAllAddress, allAddress, isLoading} = serverOnlineClients
    const tableTitles = ["Calle principal", "Calle secundaria", "Localidad", "País",  "Provincia", "Municipio", "Código postal", "Descripción"];
    useEffect(() => {
        if (client)
            getAllAddress!(client.id)
    }, []);

    const tableData: DataTableInterface[] = allAddress.map((item) => ({
        rowId: item.id,
        payload: {
            "Calle principal": item.street_1 ?? "-",
            "Calle secundaria": item?.street_2 ?? "-",
            "Localidad": item?.city ?? "-",
            "País": item?.country?.name ?? "-",
            "Provincia": item?.province?.name ?? "-",
            "Municipio": item?.municipality?.name ?? "-",
            "Código postal": item?.postalCode ?? "-",
            "Descripción": item?.description ?? "-",

        },
    }));

    const rowAction = (id: number) => {
        const current = allAddress.find((item) => item.id === id);
        if (current) {
            if (client) {
                setValue!("shipping.firstName", client?.firstName);
                setValue!("shipping.lastName", client?.lastName);
                setValue!("shipping.phone", client.phones[0]?.number);
                setValue!("shipping.email", client?.email);
                setValue!("shipping.postalCode", current?.postalCode);
                setValue!("shipping.street_1", current?.street_1);
                setValue!("shipping.street_2", current?.street_2);
                setValue!("shipping.countryId", current?.country?.id);
                setValue!("shipping.provinceId", current?.province?.id);
                setValue!("shipping.municipalityId", current?.municipality?.id);
            }
            close()
        }
    };
    return (
        <div>
            <h2 className={'text-gray-600 text-xl font-bold'}>Seleccione la dirección que desea añadir</h2>
            <div className={'mt-5'}>
                <GenericTable
                    tableTitles={tableTitles}
                    tableData={tableData}
                    rowAction={rowAction}
                    loading={isLoading}
                />
            </div>
        </div>
    )
}
