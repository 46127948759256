/* eslint-disable array-callback-return */
import { useState } from 'react'
import Button from '../components/misc/Button';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import AsyncComboBox from '../components/forms/AsyncCombobox';
import useServerReports from '../api/useServerReports';
import Modal from '../components/misc/GenericModal';
import GenericTable, { DataTableInterface } from '../components/misc/GenericTable';
import { printPriceWithCommasAndPeriods } from '../utils/functions';
import { useAppSelector } from '../store/hooks';
import Input from '../components/forms/Input';
import { GrossSalesByContainerInterface, ProdInventoryByContainerInterface } from '../interfaces/ServerInterfaces';
import { BsFiletypeXlsx } from 'react-icons/bs';
import { BtnActions } from '../components/misc/MultipleActBtn';
import SearchCriteriaComponent, { BasicTypeFilter, DateTypeFilter, SelectTypeFilter } from '../components/misc/SearchCriteriaComponent';
import useServerMain from "../api/useServerMain";

const ProductInventoryPerContainerReport = () => {


    const { business } = useAppSelector(state => state.init)

    const { costCurrency } = business!;

    const [showReportData, setshowReportData] = useState(false)
    const [exportModal, setExportModal] = useState(false);

    const {allowRoles} = useServerMain()


    const {
        getGrossSalesByContainersReport,
        grossSalesByContainersData,
        isFetching
    } = useServerReports();

    const onSubmit: SubmitHandler<Record<string, string | number>> = async (data) => {

        getGrossSalesByContainersReport(data)

        setshowReportData(true)
    };

    //Data to dislay in table ---------------------------------------------------------------------------
    let tableTitle: string[] = [
        "Nombre del producto",
        "Cantidad vendida",
        "Costo total",
        "Precio total de ventas",
        "Cantidad disponible",
        "Nombre de la carga",
    ];
    if (!allowRoles(["OWNER", "MANAGER_COST_PRICES"])) {
        tableTitle = [
            "Nombre del producto",
            "Cantidad vendida",
            "Precio total de ventas",
            "Cantidad disponible",
            "Nombre de la carga",
        ];
    }

    //Data del resultado del reporte ------------------------------------------------------------
    const tableData: DataTableInterface[] = [];
    grossSalesByContainersData?.map((item) => {
        tableData.push({
            rowId: item.batchId,
            payload: {
                "Nombre del producto": item.productName ?? "",
                "Cantidad vendida": item.quantitySales ?? "-",
                "Costo total": printPriceWithCommasAndPeriods(item.totalCost) + " " + costCurrency,
                "Precio total de ventas": item.totalSalePrices.map(elem => {
                    return printPriceWithCommasAndPeriods(elem.amount) + " " + elem.codeCurrency
                }).join(", "),
                "Cantidad disponible": item.availability ?? "",
                "Nombre de la carga": item.buyedReceiptName ?? "-",
            },
        });
    });



    const actions: BtnActions[] = [

        {
            title: "Exportar a excel",
            action: () => setExportModal(true),
            icon: <BsFiletypeXlsx />,
        },

    ];

    //Management filters ------------------------------------------------------------------------
    const availableFilters: (
        | BasicTypeFilter
        | DateTypeFilter
        | SelectTypeFilter
    )[] = [];

    availableFilters.push(
        {
            name: "dateRange",
            isRequired: true,
            label: "Rango de fechas",
            type: "datepicker-range-including-time",
            datepickerRange: [
                {
                    name: "dateFrom",
                    label: "Desde",
                    isUnitlToday: true,
                },
                {
                    name: "dateTo",
                    label: "Hasta",
                    isUnitlToday: true,
                },
            ],
        },
    );

    return (
        <div>
            <div>
                <h2 className="text-xl font-semibold mb-6">
                    Venta bruta por contenedores
                </h2>
                <div className="flex flex-col gap-2 w-full">

                    <SearchCriteriaComponent
                        filterAction={(data: FieldValues) => onSubmit(data)}
                        filters={availableFilters}
                    />
                </div>
            </div>

            {showReportData && (
                <Modal state={showReportData} close={() => setshowReportData(false)} size='l'>

                    <GenericTable
                        tableData={tableData}
                        tableTitles={tableTitle}
                        loading={isFetching}
                        actions={actions}
                    />

                </Modal>
            )}

            {exportModal && (
                <Modal state={exportModal} close={setExportModal}>
                    <ExcelFileExport
                        closeModal={() => setExportModal(false)}
                        grossSalesByContainersData={grossSalesByContainersData!}
                    />
                </Modal>
            )}

        </div>
    )
}

export default ProductInventoryPerContainerReport



interface ExportContainer {
    closeModal: Function;
    grossSalesByContainersData: GrossSalesByContainerInterface[];
}

const ExcelFileExport = ({
    closeModal,
    grossSalesByContainersData,
}: ExportContainer) => {
    const { handleSubmit, control } = useForm();
    const { exportGrossSalesByContainersReport, isFetching } = useServerReports();

    const onSubmit: SubmitHandler<Record<string, string>> = (data) => {

        exportGrossSalesByContainersReport!(grossSalesByContainersData!, data.name, closeModal)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                name="name"
                label="Nombre del archivo"
                placeholder="Nombre del archivo .xlsx"
                control={control}
                rules={{ required: "Debe indicar un nombre para el archivo" }}
            />
            <div className="flex py-2 justify-end">
                <Button
                    type="submit"
                    name="Exportar"
                    color="slate-600"
                    loading={isFetching}
                    disabled={isFetching}
                />
            </div>
        </form>
    );
};